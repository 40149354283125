import React, { useLayoutEffect, useState } from "react";
import {
  Card,
} from 'react-bootstrap'

const styles = {
  card: {
    margin: 'auto',
  },
  cardImage: (size) => {
    return {
      objectFit: (size[0]>=1440)?'cover':((size[0]<768)?'none':'cover'),
      height: '12rem',
    }
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: (size) => {
    return {
      width: '100%',
      textAlign: 'center',
      color: '#FFF',
      fontFamily: 'Gosanja',
      fontWeight: '300', 
      fontSize:(size[0]>=1440)?'45px':((size[0]<768)?'30px':'3.2vw'),
    }
  },
}

const PageTitleBanner = ({title, imageUrl}) => {
  let [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {   
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Card style={styles.card}>
      <Card.Img src={imageUrl} alt="Card image" style={styles.cardImage(size)} />
      <Card.ImgOverlay style={styles.imageOverlay}>
        <Card.Title style={styles.title(size)}>{title}</Card.Title>
      </Card.ImgOverlay>
    </Card>
  )
}

export default PageTitleBanner
