import React from 'react'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'

const SignInCallback = () => {
  const parsedHash = queryString.parse(window.location.hash)
  localStorage.setItem('keid_user_token', parsedHash.id_token)

  return (
    <>
      <Redirect to={parsedHash.state} />
    </>
  )
}

export default SignInCallback
