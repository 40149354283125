import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  Button,
  Col,
  Card,
  // Pagination,
  Row,
  ListGroup,
  Spinner
} from 'react-bootstrap'
import {
  Link
  } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { themeColors } from '../Styles'
import { useToasts } from 'react-toast-notifications'
import { getCategoryPosts, updateFavouriteCategoryPosts } from '../infrastructure/ApisHandler'
import { signedIn } from '../infrastructure/TokenHelper'

const styles = {
  body: {
    marginBottom: '0rem',
    paddingTop: '0rem',
    paddingBottom: '1rem'
  },
  pageRow: {
    marginBottom: '3rem',
  },
  card: {
    margin: 'auto 0px',
    padding: 'auto 0px',
    borderWidth: 0,
    borderRadius: '20px 0px 20px 0px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    padding: '0rem 0rem',
    textAlign: 'left',
    fontWeight: 'bold',
    fontColor: '#2B2B2B',
    fontSize: '20px'
  },
  cardBody: {
    margin: 'auto 0px',
    // paddingLeft: '0px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'medium',
    color: '#2B2B2B'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    borderWidth:0,
    padding: '0.25rem 0rem',
  },
  content: {
    fontSize: '14px',
    fontWeight: '300',
    color: '#2B2B2B',
  },
  info: {
    marginBottom: -3,
    paddingBottom: 0,
    fontSize: '11px',
    fontWeight: '300',
    color: '#155799',
  },
  icon: {
    marginLeft: '10px',
    color: themeColors.primary,
    fontSize: '11px',
  },
  noticeText: {
    border: `solid 2px ${themeColors.primary}`, 
    borderRadius: '5px', 
    color: themeColors.primary, 
    fontWeight: 'bold', 
    fontSize: '12px', 
    padding: '2px', 
    marginRight: '7px'
  },
  button: {
    width: 130,
    backgroundColor: themeColors.button,
    color: '#FFF',
    borderWidth: 0,
    borderRadius: 20,
    marginRight: 20,
  }
}

const PostItemsDE = ({posts}) => {
  const [userSignedIn, setUserSignedIn] = useState(false)
  let [size, setSize] = useState([0, 0])
  const [spinning, setSpinning] = useState(false)
  const [updatePosts, setUpdatePosts] = useState([])
  const { addToast } = useToasts()

  useLayoutEffect(() => {
    function updateSize() {   
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    (() => {

      setUpdatePosts(posts)

      const idToken = localStorage.getItem('keid_user_token')
      if (idToken) {
        const decoded = jwt_decode(idToken)
        const notExpired = Date.now() < decoded.exp * 1000
        setUserSignedIn(notExpired)
      }

    })()
  }, [posts])

  const actionFavourite = async (category, id, value) => {
    if (!signedIn()) {
      addToast("새로 로그인해주시기 바랍니다.", {
        appearance: 'error',
        autoDismiss: true
      })

      return
    }

    setSpinning(true)

    await updateFavouriteCategoryPosts(category, id, value)
    const json = await getCategoryPosts(category)
    setUpdatePosts(json.data || [])

    setSpinning(false)
  }

  return (
    <>
      {
        (spinning) &&
          <Spinner
            variant="warning"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            style={{zIndex: 999, position: 'absolute', top: '50%', left: '50%', marginRight:'10px'}}
          />
      }
      <Row style={styles.body}>
          <Col md={12} >

            <Card style={styles.card}>
              <Card.Body style={styles.cardBody}>
                <Card.Title style={styles.cardHeader}>
                  <span>Liste</span>
                </Card.Title>
                <hr />
                <ListGroup>
                  {updatePosts.map((x,i) => (
                    <ListGroup.Item style={styles.listItem} key={i}>
                      <Link to={`/${x.category}/${x.postId}`} >
                        <span style={styles.content}>
                          {x.favourite ? <span style={styles.noticeText}>Beachten</span> : ''}
                          {`${x.title.substring(0,
                            (size[0]>=1200) 
                            ? 60
                            : (size[0]>992)
                              ? 45
                              : (size[0]>768)
                                ? 30
                                : (size[0]>576)
                                  ? 30
                                  : (size[0]>374)
                                    ? 15
                                    : 7
                            )}`}
                        </span>
                      </Link>
                      <span style={styles.info}>
                        {x.dateTimestamp.split('T')[0]} 
                        {
                          signedIn() && (x.favourite 
                            ? <i className="fas fa-star" style={styles.icon} onClick={() => actionFavourite(x.category, x.postId, false)}></i>
                            : <i className="far fa-star" style={styles.icon} onClick={() => actionFavourite(x.category, x.postId, true)}></i>
                          )
                        }
                      </span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>

          </Col>
      </Row>
      <Row style={styles.pageRow}>
        <Col md={{span:3}} >
          {
            userSignedIn &&
            <Button style={styles.button} href="/write-post">schreiben</Button>
          }
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
        {/* <Col md={10} style={{textAlign: 'right'}}>
          <Pagination size="sm" style={{display: 'inline-flex', paddingRight: '15px'}}>
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />

            <Pagination.Item>{10}</Pagination.Item>
            <Pagination.Item>{11}</Pagination.Item>
            <Pagination.Item active>{12}</Pagination.Item>
            <Pagination.Item>{13}</Pagination.Item>
            <Pagination.Item disabled>{14}</Pagination.Item>

            <Pagination.Ellipsis />
            <Pagination.Item>{20}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last />
          </Pagination>
        </Col> */}
      </Row>
  </>
  )
}

export default PostItemsDE
