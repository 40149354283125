import React, { useLayoutEffect, useState } from 'react'
import { 
  Row, 
  Col, 
  Carousel,
  Image,
} from "react-bootstrap";
import { useSpring } from 'react-spring'

const styles = {
  slide: {
    background: 'linear-gradient(rgba(250,0,0,0.5),transparent)',
    backgroundColor: '#000'
  },
  slideLayout: {
    paddingRight: "0px",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  slideSection: {
    top: 0,
    left: 0,
    width: '100%',
    padding: '2% 4%',
    textAlign: 'left',
    // background: 'linear-gradient(rgba(243,115,53,0.4),transparent)',
    background: 'linear-gradient(rgba(0,0,0,0.4),transparent)',
    // background: 'linear-gradient(rgba(87,87,87,0.6),transparent)',
  },
  slideTitle: {
    fontSize: '2vw',
    fontWeight: 'bold',
    color: '#FFF',
    // textShadow: '2px 2px 3px rgba(0, 0, 0, 0.5)'
  },
  slideTag: {
    fontSize: '1vw',
    fontWeight: 'bold',
    color: '#FFF',
    marginBottom: 0,
    // textShadow: '2px 2px 3px rgba(0, 0, 0, 0.5)'
  },
  buttonSpring: (size) => {
    return ({
      zIndex: 999,
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: -44,
      left: size[0]/2-15,
      width: 62,
      height: 62,
      borderRadius:50,
      fontSize: '45px',
      fontWeight: '300',
      color: '#575757',
      backgroundColor: '#FFFFFF',
      borderWidth: 0,
      boxShadow: '1px 4px 10px rgba(118, 118, 118, 0.8)',
      transition: 'all 0.3s ease 0s',
      cursor: 'pointer',
      outline: 'none',
    })
  }
}

const ImageSlider= ({slides}) => {
  const [y, setY] = useSpring(() => ({
    immediate: false,
    y: 0,
    onFrame: props => {
      window.scroll(0, props.y);
    },
    config: {duration: 250},
  }));

  let [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {   
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Row style={styles.row}>
      <Col md={12}>
        <Carousel style={styles.slide}>
          {
            slides.map((x,i) => {
              
              return(
              <Carousel.Item style={styles.slideLayout} key={i}>
                <Image
                  className="d-block w-100"
                  src={x.image}
                />
                <Carousel.Caption style={styles.slideSection}>
                  <div style={styles.slideTitle}>
                    {x.title}
                  </div>
                  <div style={styles.slideTag}>
                    {x.taglines.map(t => <>{t}<br /></>)}
                  </div>
                  
                </Carousel.Caption>
              </Carousel.Item>
              )
            })
          }
        </Carousel>

        <button
          variant="primary"
          onClick={() => {
            setY({ y: window.innerHeight });
          }}

          style={styles.buttonSpring(size)}
        >
          <i className="fas fa-sort-down" style={{paddingBottom: 20}}></i>
        </button>

      </Col>
    </Row>
  )
}

export default ImageSlider
