import React, { useLayoutEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Card,
} from 'react-bootstrap'
import {
  Link
} from 'react-router-dom'
import Categories from "../infrastructure/Categories";

const styles = {
  sectionTitle: {
    textAlign: 'center', 
    fontFamily: 'Gosanja',
    fontWeight: '300', 
    fontSize: '32px', 
    color: '#575757' 
  },
  body: {
    marginBottom: '2rem',
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  col: {
    margin: '10px auto'
  },
  card: {
    borderRadius: '20px 0px 20px 0px',
    // borderColor: '#FAFAFB',
    borderWidth: '0',
    boxShadow: '5px 9px 20px rgba(118, 118, 118, 0.45)',
  },
  cardImage: (size) => {
    return {
      borderRadius: '20px 0px 0px 0px',
      // width: (size[0]>=1440)?'cover':((size[0]<768)?'none':'cover'),
    }
  },
  content: (size) => {
    return {
      textAlign: 'center', 
      fontSize: (size[0]>=1200)?'20px':(size[0]>992)?'20px':(size[0]>768)?'20px':(size[0]>576)?'24px':'20px',  
      fontWeight: 'bold', 
    }
  },
  info: (size) => {
    return {
      textAlign: 'center', 
      fontSize:(size[0]>=1200)?'12px':(size[0]>992)?'12px':(size[0]>768)?'12px':(size[0]>576)?'15px':'12px', 
      fontWeight: 'normal', 
      color: '#767676'
    }
  },
  more: (size) => {
    return {
      fontSize:(size[0]>=1200)?'14px':(size[0]>992)?'14px':(size[0]>768)?'14px':(size[0]>576)?'18px':'14px',  
    }
  }
}

const Education = () => {
  let [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {   
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <Row>
        <Col md={12} style={styles.sectionTitle}>
          <div><font className="primary-title" >주독일한국교육원</font>의 업무 자세히 보기</div>
        </Col>
      </Row>
      <Row style={styles.body}>
        <Container>
          <Row>
            <Col xl={3} lg={3} md={6} sm={10} xs={10} style={styles.col}>
              <Card style={styles.card}>
                <Link to='/keid-school-info' >
                  <Card.Img variant="top" 
                    src="/images/maincard-01-koreanschool.png" 
                    style={styles.cardImage(size)}/>
                </Link>
                <Card.Body style={{textAlign: 'center'}}>
                  <Card.Link href='/keid-school-info' className="primary-title" style={styles.content(size)}>
                    한글학교
                  </Card.Link>
                  {/* <Card.Text style={styles.info(size)}>
                    독일주재<br />한글학교 소개
                  </Card.Text> */}
                  <hr />
                  <Card.Link className="primary-title" style={styles.more(size)} href='/keid-school-info'>
                    더보기 +
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={3} lg={3} md={6} sm={10} xs={10} style={styles.col}>
              <Card style={styles.card}>
                <Link to={`/${Categories.koreanLanguageAdoption}`} >
                  <Card.Img variant="top" src="/images/maincard-02-koreanAG.png" style={{borderRadius: '20px 0px 0px 0px' }} />
                </Link>
                <Card.Body style={{textAlign: 'center'}}>
                  <Card.Link href={`/${Categories.koreanLanguageAdoption}`} className="primary-title" style={styles.content(size)}>
                    독일 초중등학교 한국어 채택
                  </Card.Link>
                  {/* <Card.Text style={styles.info(size)}>
                    독일초중등학교의<br/>한국어 및 한국문화수업
                  </Card.Text> */}
                  <hr />
                  <Card.Link className="primary-title" style={styles.more(size)} href={`/${Categories.koreanLanguageAdoption}`}>
                    더보기 +
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={3} lg={3} md={6} sm={10} xs={10} style={styles.col}>
            <Card style={styles.card}>
                <Link to={`/${Categories.koreanClass}`} >
                  <Card.Img variant="top" src="/images/maincard-03-class.png" style={{borderRadius: '20px 0px 0px 0px' }}/>
                </Link>
                <Card.Body style={{textAlign: 'center'}}>
                  <Card.Link href={`/${Categories.koreanClass}`} className="primary-title" style={styles.content(size)}>
                    한국어강좌
                  </Card.Link>
                  {/* <Card.Text style={styles.info(size)}>
                    교육원 운영<br />일반인 대상 한국어 강좌
                  </Card.Text> */}
                  <hr />
                  <Card.Link className="primary-title" style={styles.more(size)} href={`/${Categories.koreanClass}`}>
                    더보기 +
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={3} lg={3} md={6} sm={10} xs={10} style={styles.col}>
            <Card style={styles.card}>
                <Link to={`/${Categories.koreanTest}`} >
                  <Card.Img variant="top" src="/images/maincard-04-exam.png" style={{borderRadius: '20px 0px 0px 0px' }}/>
                </Link>
                <Card.Body style={{textAlign: 'center'}}>
                  <Card.Link href={`/${Categories.koreanTest}`} className="primary-title" style={styles.content(size)}>
                    한국어능력시험
                  </Card.Link>
                  {/* <Card.Text style={styles.info(size)}>
                    주독한국교육원<br />자료실
                  </Card.Text> */}
                  <hr />
                  <Card.Link className="primary-title" style={styles.more(size)} href={`/${Categories.koreanTest}`} >
                    더보기 +
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  );
};

export default Education;
