import React, {
  useEffect,
  useState
} from 'react'
import {
  Container,
  Row,
  Col,
  Spinner
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import Categories from '../infrastructure/Categories'
import {getCategoryPosts} from '../infrastructure/ApisHandler'
import PostItemsDE from '../components/PostItemsDE'
import PageTitleBanner from '../components/PageTitleBanner'
import SidebarMenuDE from '../components/SidebarMenuDE'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    marginTop: '-14px',
    paddingBottom: '7%',
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  },
  sidebarTitle: {
    paddingLeft: 0,
    backgroundColor:"#FAFAFB",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 'solid 3px',
    borderRadius: '20px 0px 0px 0px',
    color: themeColors.primary,
    fontWeight:'bold',
    fontSize: '20px'
  },
  sidebarMenu: {
    backgroundColor:"#FAFAFB",
    paddingLeft: 0,
    borderWidth:0,
    color: '#767676',
    fontsize: '14px',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.125)',
  }
}

const ListPostsDE = ({category}) => {
  const [spinning, setSpinning] = useState(false)
  const [posts, setPosts] = useState([])

  const titles = {}
  titles[Categories.keidAnnouncementDE] =  { title:'Aktuelles', background:'/images/title-banner-bg-o.png' }
  titles[Categories.koreanischAG] =  { title:'Koreanisch AG', background:'/images/title-banner-bg-o.png' }
  titles[Categories.koreanischKurse] =  { title:'Koreanisch-Kurse', background:'/images/title-banner-bg-o.png' }
  titles[Categories.studiumInKorea] =  { title:'Studium In Korea', background:'/images/title-banner-bg-o.png' }
  titles[Categories.topikDE] =  { title:'TOPIK', background:'/images/title-banner-bg-o.png' }
  document.title = titles[category].title

  useEffect(() => {
    (async () => {
      setSpinning(true)

      const json = await getCategoryPosts(category)
      setPosts(json.data || [])

      setSpinning(false)
    })()
  }, [category])

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      {
        (spinning) &&
          <Spinner
            variant="warning"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            style={{zIndex: 999, position: 'absolute', top: '50%', left: '50%', marginRight:'10px'}}
          />
      }

      <Container fluid style={styles.headerContainer}>
      <PageTitleBanner
          imageUrl={titles[category].background}
          title={titles[category].title}
        />
      </Container>
      <Container style={styles.mainContainer} >
        <Row>
          <Col
            xl={{span: 9, offset:0}}
            lg={{span: 9, offset:0}}
            md={{span: 12, offset:0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <PostItemsDE posts={posts} />
          </Col>
          <Col style={styles.colSidebar}
            xl={{span: 3, offset:0}}
            lg={{span: 3, offset:0}}
            md={{span: 12, offset:0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <SidebarMenuDE />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ListPostsDE
