import React from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Image
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import PageTitleBanner from '../components/PageTitleBanner'
import SidebarMenu from '../components/SidebarMenu'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  row: {
    marginBottom: '7%'
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    marginTop: '-14px',
    paddingBottom: '7%',    
  },
  imageColumnRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  },
  introTitle: {
    marginBottom: '38px',
    color: themeColors.primary,
  },
  introSubtitle: {
    marginBottom: '23px',
    color: '#2B2B2B',
    fontWeight: 'bold'
  },
  subtitleTag: {
    marginBottom: '0px',
    color: themeColors.primary,
  },
  historyDetail: {
    color: themeColors.primary,
    fontSize: '14px'
  },
  button: {
    width:'150px', 
    borderRadius:50,
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  card: {
    // height: '250px',
    marginBottom: '10px',
    paddingLeft: 20,
    borderWidth: 0,
    borderRadius: '7px 7px 7px 7px',
    boxShadow: '0 2px 10px 0 rgba(21, 87, 153, 0.1), 0 4px 15px 0 rgba(21, 87, 153, 0.05)'
  },
  cardImage: {
    height: 22,
    marginRight: 10,
    verticalAlign: 'top'
  },
  cardTitle: {
    fontSize: '20px',
    color: '#767676',
    fontWeight: 'bold'
  }
  ,
  cardText: {
    fontSize: '14px',
    color: '#575757',
    fontWeight: 'normal'
  }
}


const Services = () => {
  document.title = "교육원 주요업무"
  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner  
          imageUrl="/images/title-banner-bg-o.png" 
          title="교육원 주요 업무" 
        />
      </Container>
      <Container style={styles.mainContainer} id="services">
        <Row>
          <Col 
            xl={{span: 9, offset: 0}}
            lg={{span: 9, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            {/* <Row>
              <Col md={8}>
                <p style={styles.subtitleTag}>주독일한국교육원</p>
                <h1 style={styles.introSubtitle}>주요업무</h1>
              </Col>
              <Col md={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Button className="float-right" variant="primary" style={styles.button}>
                  <i className="far fa-arrow-alt-circle-right" style={{marginRight: 10}}></i>문의하기
                </Button>
              </Col>
            </Row> */}
            <Row style={styles.row}>
              <Col md={12}>
                <Card style={styles.card}>
                  <Card.Body>
                    <Card.Title>
                      <span><Image src="/images/box-icon-01.png" style={styles.cardImage}/></span>
                      <span>한국어 보급 활동</span>
                    </Card.Title>
                    <Card.Text style={styles.cardText}>
                      - 독일 초.중등학교 내 한국어 과목 개설 및 운영 지원 <br />
                      - 교육원 한국어강좌 개설.운영 <br />
                      - 한국어능력시험(TOPIK) 시행 <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card style={styles.card}>
                  <Card.Body>
                    <Card.Title>
                      <span><Image src="/images/box-icon-02.png" style={styles.cardImage}/></span>
                      <span>한글학교 교육활동 지원</span>
                    </Card.Title>
                    <Card.Text style={styles.cardText}>
                    - 한글학교 운영비 지원 및 장학 <br/>
                    - 한글학교 개설 및 신규 등록 지원 <br/>
                    - 한글학교 교과서 및 교재 지원 <br/>
                    - 한글학교장협의회 행사 및 활동 지원 <br/>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card style={styles.card}>
                  <Card.Body>
                    <Card.Title>
                      <span><Image src="/images/box-icon-03.png" style={styles.cardImage}/></span>
                      <span>유학생 지원 및 유치 활동</span>
                    </Card.Title>
                    <Card.Text style={styles.cardText}>
                      - 국비유학생 상담 및 지도 <br/>
                      - 대한민국 정부초청외국인 대학원 장학생(GKS) 선발.추천 <br/>
                      - 한국 대학 유학정보 안내 및 유학생 유치활동 지원 <br/>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card style={styles.card}>
                  <Card.Body>
                    <Card.Title>
                      <span><Image src="/images/box-icon-04.png" style={styles.cardImage}/></span>
                      <span>한.독 교육교류 지원</span>
                    </Card.Title>
                    <Card.Text style={styles.cardText}>
                      - 한국과 독일의 학교 및 기관의 교육교류 활동 지원 <br/>
                      - 본국 연수 및 방문교류 활동 지원 <br/>
                      - 교육자료, 홍보물, 정기간행물 배포 및 독일 교육관련 자료 수집 <br/>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card style={styles.card}>
                  <Card.Body>
                    <Card.Title>
                      <span><Image src="/images/box-icon-01.png" style={styles.cardImage}/></span>
                      <span>독일 내 동포 교육활동 지원</span>
                    </Card.Title>
                    <Card.Text style={styles.cardText}>
                    - 모국방문 연수 프로그램 홍보 및 추천 <br/>
                    - 교민단체 교육 관련 행사 지원 <br/>
                    - 각종 교육관련 상담 및 안내(본국 대학입시, 학력인정학교 등) <br/>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col style={styles.colSidebar} 
            xl={{span: 3, offset: 0}}
            lg={{span: 3, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <SidebarMenu />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Services
