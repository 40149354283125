const Categories = {
    keidAnnouncement: "keid-announcement",
    keidNews: "keid-news",
    koreanSchoolNewsAnnouncement: "korean-school-news-announcement",
    koreanSchoolNews: "korean-school-news",
    koreanSchoolAnnouncement: "korean-school-announcement",
    koreanLanguageAdoption: "korean-language-adoption",
    koreanClass: "korean-class",
    koreanTest: "korean-test",
    germanEducationSystem: "german-education-system",
    germanEducationInfo: "german-education-info",
    studyInKoreaInfo: "study-in-korea-info",
    studyInKoreaAnnouncement: "study-in-korea-announcement",
    keidSchoolInfo: "keid-school-info",
    keidAnnouncementDE: "keid-announcement-de",
    koreanischAG: "koreanisch-ag",
    koreanischKurse: "koreanisch-kurse",
    studiumInKorea:  "studium-in-korea",
    topikDE:  "topik-de",
    websiteNotice: "website-notice"
}

export default Categories
