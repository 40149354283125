import React from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Image
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import PageTitleBanner from '../components/PageTitleBanner'
import SidebarMenuDE from '../components/SidebarMenuDE'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  row: {
    marginBottom: '7%'
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    marginTop: '-14px',
    paddingBottom: '7%',    
  },
  imageColumnRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  },
  introTitle: {
    marginBottom: '38px',
    color: themeColors.primary,
  },
  introSubtitle: {
    marginBottom: '23px',
    color: '#2B2B2B',
    fontWeight: 'bold'
  },
  subtitleTag: {
    marginBottom: '0px',
    color: themeColors.primary,
  },
  historyDetail: {
    color: themeColors.primary,
    fontSize: '14px'
  },
  button: {
    width:'150px', 
    borderRadius:50,
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  card: {
    // height: '250px',
    marginBottom: '10px',
    paddingLeft: 20,
    borderWidth: 0,
    borderRadius: '7px 7px 7px 7px',
    boxShadow: '0 2px 10px 0 rgba(21, 87, 153, 0.1), 0 4px 15px 0 rgba(21, 87, 153, 0.05)'
  },
  cardImage: {
    height: 22,
    marginRight: 10,
    verticalAlign: 'top'
  },
  cardTitle: {
    fontSize: '20px',
    color: '#767676',
    fontWeight: 'bold'
  }
  ,
  cardText: {
    fontSize: '14px',
    color: '#575757',
    fontWeight: 'normal'
  }
}


const ServicesDE = () => {
  document.title = "Aufgabe"

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner  
          imageUrl="/images/title-banner-bg-o.png" 
          title="Aufgabe" 
        />
      </Container>
      <Container style={styles.mainContainer} id="services">
        <Row>
          <Col 
            xl={{span: 9, offset: 0}}
            lg={{span: 9, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            {/* <Row>
              <Col md={8}>
                <p style={styles.subtitleTag}>Koreanisches Erziehungsinstitut in Deutschland</p>
                <h1 style={styles.introSubtitle}>Die Hauptaufgabe</h1>
              </Col>
              <Col md={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Button className="float-right" variant="primary" style={styles.button}>
                  <i className="far fa-arrow-alt-circle-right" style={{marginRight: 10}}></i>Anfragen
                </Button>
              </Col>
            </Row> */}
            <Row style={styles.row}>
              <Col md={12}>
                <Card style={styles.card}>
                  <Card.Body>
                    <Card.Title>
                      <span><Image src="/images/box-icon-01.png" style={styles.cardImage}/></span>
                      <span>Betreuung und Förderung der koreanischen Schulen in Deutschland</span>
                    </Card.Title>
                    <Card.Text style={styles.cardText}>
                      - Finanzielle Förderung <br />
                      - Versorgung der Lehrbücher und Lehrmaterialien <br />
                      - Beratung bei der Entwicklung von Unterrichtsmethoden <br />
                      - Präsenzberatung bei der Schulveranstaltung <br />
                      - Beratung bei der Eröffnung und Zusammenschließung der Schulen <br />
                      - Verfassung der Lerhmaterialien für deutsche Muttersprachler <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card style={styles.card}>
                  <Card.Body>
                    <Card.Title>
                      <span><Image src="/images/box-icon-02.png" style={styles.cardImage}/></span>
                      <span>Förderung der koreanischen Sprache und Kultur</span>
                    </Card.Title>
                    <Card.Text style={styles.cardText}>
                    - Beratung und Empfehlung der koreanischen Sprachkurse und Kulturprogramme vom "National Institute for International Education Development" und von der "Korean Overseas Foundation" <br />
                    - Veranstaltung des "Korean Language Proficiency Test" <br />
                    - Beratung und Informationen über das Studium in Korea und die Sprachkurse der koreanischen Universitäten <br />
                    - Beratung und Teilnehmerauswahl der verschiedenen Workshops in Korea <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card style={styles.card}>
                  <Card.Body>
                    <Card.Title>
                      <span><Image src="/images/box-icon-03.png" style={styles.cardImage}/></span>
                      <span>Bildungsaustausch und Förderung der internationalen Zusammenarbeit</span>
                    </Card.Title>
                    <Card.Text style={styles.cardText}>
                    - Recherche über das deutsche Bildungssystem auf die Anfrage aus Korea <br />
                    - Besuchsanfrage an deutsche Bildungseinrichtungen und Betreuung der koreanischen Delegation <br />
                    - Beratung und Vermittlung der Partnerschaften zwischen koreanischen und deutschen Bildungsreinrichtungen <br />
                    - Betreuung der koreanischen Regierungsstipendiaten in Deutschland <br />
                    - Informationsaustausch durch aktuelle Nachrichten im Bildungsbereich <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col style={styles.colSidebar} 
            xl={{span: 3, offset: 0}}
            lg={{span: 3, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <SidebarMenuDE />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ServicesDE
