import { getRawToken } from "../infrastructure/TokenHelper"
import config from "../config.json"

const getPageContents = async (category) => {
  const url = `${config.API_HOST}/contents/${category}`;

  try {
    const response = await fetch(url);
    return await response.json();  
  } catch (error) {
    return { result: false, message: error.stringify }
  }
};

const getContent = async (category, id) => {
  const response = await fetch(`${config.API_HOST}/contents/${category}/${id}`);
  return await response.json();
};

const writeContent = async (id, title, content, category, displayOrder) => {
  let url = `${config.API_HOST}/contents`;
  if (id) url += `/${category}/${id}`;

  const method = id ? "PUT" : "POST";

  try {
    const response = await fetch(url, {
      method: method,
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getRawToken()}`,
      },
      body: JSON.stringify({
        title: title,
        content: content,
        category: category,
        displayOrder: displayOrder,
      })})
  
    return { result: true, data: await response.json() }
  } catch (error) {
    return { result: false, message: error.message }
  }

  // fetch(url, {
  //   method: method,
  //   mode: "cors",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${getRawToken()}`,
  //   },
  //   body: JSON.stringify({
  //     title: title,
  //     content: content,
  //     category: category,
  //     displayOrder: displayOrder,
  //   }),
  // }).then((response) => {
  //   if (response.ok) {
  //     return { result: true, data: response.json() }
  //   }
  //   return { result: false, message: response.statusText }
  // })
  // .catch((error) => {
  //   return { result: false, message: error }
  // })
};

const getCategoryPosts = async (category) => {
  let url = `${config.API_HOST}/categoryposts`;
  url += `/${category}`;

  const response = await fetch(url);
  return await response.json();
};

const updateFavouriteCategoryPosts = async (category, id, payload) => {
  let url = `${config.API_HOST}/categoryposts/${category}/${id}`;

  const response = await fetch(url, {
    method: "PATCH",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getRawToken()}`,
    },
    body: JSON.stringify({
      favourite: payload,
    }),
  });
  return await response.json();
};

const getPost = async (id) => {
  const response = await fetch(`${config.API_HOST}/posts/${id}`);
  return await response.json();
};

const getViewCount = async (id) => {
  const response = await fetch(`${config.API_HOST}/viewcounts/${id}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `NONE`,
    },
    body: {},
  });

  return await response.json();
};

const writePost = async (
  id,
  title,
  content,
  categories,
  attachments,
  thumbnailUrl
) => {
  let url = `${config.API_HOST}/posts`;
  if (id) url += `/${id}`;

  const method = id ? "PUT" : "POST";

  const response = await fetch(url, {
    method: method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getRawToken()}`,
    },
    body: JSON.stringify({
      title: title,
      content: content,
      categories: categories,
      attachments: attachments,
      thumbnailUrl: thumbnailUrl,
    }),
  });

  return await response.json();
};

const deletePost = async (id) => {
  let url = `${config.API_HOST}/posts`;
  if (id) url += `/${id}`;

  const response = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getRawToken()}`,
    },
  });

  return await response.json();
};

const deletePostAttachment = async (id, key) => {
  let url = `${config.API_HOST}/posts`;
  if (id) url += `/${id}/attachments/${key}`;

  const response = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getRawToken()}`,
    },
  });

  return await response.json();
};

const uploadAttachment = async (file) => {
  const initialResponse = await fetch(`${config.API_HOST}/attachments`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "appliation/json",
      Authorization: `Bearer ${getRawToken()}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify({
      filename: file.name,
    }),
  });

  const json = await initialResponse.json();

  if (!initialResponse.ok) {
    return {
      ok: initialResponse.ok,
      message: json.message,
    };
  }

  const attachment = json.data;

  await fetch(attachment.signedUrl, {
    method: "PUT",
    mode: "cors",
    body: file,
  });

  return {
    attachment: attachment,
    ok: true,
    message: "",
  };
};

const createThumbnailImage = async (key) => {
  const url = `${config.API_HOST}/thumbnails`;

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getRawToken()}`,
    },
    body: JSON.stringify({ key: key }),
  });

  return await response.json();
};

export {
  getPageContents,
  getContent,
  writeContent,
  getCategoryPosts,
  updateFavouriteCategoryPosts,
  getPost,
  getViewCount,
  writePost,
  deletePost,
  deletePostAttachment,
  uploadAttachment,
  createThumbnailImage,
};
