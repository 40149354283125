import React, { useLayoutEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import PageTitleBanner from '../components/PageTitleBanner'
import ListSchools from '../components/ListSchools'
import SidebarMenu from '../components/SidebarMenu'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  colRight: {
    alignSelf: 'flex-start',
    padding: '0rem 1rem 2rem 1rem',
    // margin: 'auto 2rem'
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    marginTop: '-14px',
    paddingBottom: '7%',
  },
  colImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  dynamicImage: (size) => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // width: (size[0]>=1200)
      //   ? '400px'
      //   : (size[0]>992)
      //     ? '250px'
      //     : (size[0]>768)
      //       ? '500px'
      //       : (size[0]>576)
      //         ? '400px'
      //         : (size[0]>374)
      //           ? '300px'
      //           : '300px',
      width: '100%',
      objectFit: 'contain',
      textAlign: 'center'
    }
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  },
  sectionTag: {
    marginBottom: '0px',
    color: themeColors.primary,
  },
  sectionTitle: {
    marginBottom: '25px',
    color: '#2B2B2B',
    fontFamily: 'Gosanja',
    fontWeight: '300', 
    fontSize: '32px',
  },
  sectionDescription: {
    color: '#575757',
    fontSize: '14px',
    textAlign: 'left'
  },
  historyDetail: {
    paddingLeft: '10px',
    color: '#575757',
    fontSize: '14px',
    textAlign: 'left'
  },
  button: {
    width:'192px',
    borderRadius:50,
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  card: {
    height: '250px',
    marginBottom: '10px',
    borderWidth: 0,
    borderRadius: '7px 7px 7px 7px',
    boxShadow: '0 2px 10px 0 rgba(21, 87, 153, 0.1), 0 4px 15px 0 rgba(21, 87, 153, 0.05)'
  },
  cardSubtitle: {
    fontSize: '19px',
    color: '#2B2B2B',
    fontWeight: 'bold'
  }
  ,
  cardText: {
    fontSize: '14px',
    color: '#575757',
    fontWeight: 'normal'
  }
}


const SchoolPortal = () => {
  document.title = "한글학교 소개"
  let [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner
          imageUrl="/images/title-banner-bg-b.png"
          title="한글학교"
        />
      </Container>
      <Container style={styles.mainContainer} >
        <Row style={styles.row}>
          <Col style={styles.colImage}
            xl={{span: 4, offset: 0}}
            lg={{span: 4, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <Image src="/images/germanschool-map.png" style={styles.dynamicImage(size)} />
          </Col>

          <Col style={styles.colRight}
            xl={{span: 5, offset: 0}}
            lg={{span: 5, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <p style={styles.sectionTag}>독일에서 운영중인 한글학교를 소개합니다</p>
            {/* <h1 style={styles.sectionTitle}>한글학교 소개</h1> */}
            <ListSchools />
          </Col>
          <Col style={styles.colSidebar}
            xl={{span: 3, offset: 0}}
            lg={{span: 3, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <SidebarMenu />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SchoolPortal
