import React from "react";
import {
  Col,
  Row,
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import { signedIn } from '../infrastructure/TokenHelper'

const styles = {
  header: {
    margin: '1rem 0px',
    padding: '5px 0px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: `${themeColors.secondary}`,
    borderBottom: `solid 4px ${themeColors.secondary}`,
  },
  icon: {
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 'normal',
    color: themeColors.primary,
    cursor: 'pointer'
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  info: {
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: '400',
    color: themeColors.secondary,
  },
}

const ListAttachments = ({title, items, actionDelete}) => {

  return (
    <>
      <div className="primary-title" style={styles.header}>{title}</div>
      {
        (items) &&
          items.map((x,i) => (
            <Row key={i} style={styles.list}>
              <Col md={11}>
                <a href={x.objectUrl} download={x.name} target="_blank" rel="noreferrer" style={styles.info}>
                  <i className="fas fa-paperclip"></i> {x.name}
                </a>
              </Col>
              <Col md={1} style={{textAlign: 'right'}}>
                {
                  signedIn() &&
                  <i className="far fa-times-circle"
                    style={styles.icon}
                    onClick={() => actionDelete(x.key, x.name)}>
                  </i>
                }
              </Col>
            </Row>
          ))
      }
    </>
  )
}

export default ListAttachments
