import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Spinner,
  Pagination
} from 'react-bootstrap'
import GalleryItems from '../components/GalleryItems'
import Categories from '../infrastructure/Categories'
import {getCategoryPosts} from '../infrastructure/ApisHandler'
import PageTitleBanner from '../components/PageTitleBanner'
import SidebarMenu from '../components/SidebarMenu'


const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    marginTop: '-14px',
    paddingBottom: '7%',
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  }
}

const ListNews = ({category}) => {
  const pageSize = 10
  const [spinning, setSpinning] = useState(false)
  const [posts, setPosts] = useState([])
  const [pagedPosts, setPagedPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const titles = {}
  titles[Categories.keidNews] =  { title:'교육원 새소식', background:'/images/title-banner-bg-o.png' }
  titles[Categories.koreanSchoolNews] = { title:'한글학교 새소식', background: '/images/title-banner-bg-b.png' }
  document.title = titles[category].title

  useEffect(() => {
    (async () => {
      setSpinning(true)

      const json = await getCategoryPosts(category)
      setPosts(json.data || [])

      console.log(json.data)

      setSpinning(false)
    })()
  }, [category])

  useEffect(() => {
    setPagedPosts(posts.slice(pageSize*(0), pageSize*1))
    setCurrentPage(1)
  }, [posts])

  const handlePagination = (pageNumber) => {
    setPagedPosts(posts.slice(pageSize*(pageNumber-1), pageSize*pageNumber))
    setCurrentPage(pageNumber)
  }

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      {
        (spinning) &&
          <Spinner
            variant="warning"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            style={{zIndex: 999, position: 'absolute', top: '50%', left: '50%', marginRight:'10px'}}
          />
      }

      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner
          imageUrl={titles[category].background}
          title={titles[category].title}
        />
      </Container>
      <Container style={styles.mainContainer} >
        <Row>
          <Col
            xl={{span: 9, offset:0}}
            lg={{span: 9, offset:0}}
            md={{span: 12, offset:0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <GalleryItems posts={pagedPosts}/>
            <Pagination className="d-flex justify-content-center">
              <Pagination.Prev 
                disabled={currentPage < 2}
                onClick={() => handlePagination(currentPage - 1)} 
                />
              <Pagination.Next 
                disabled={currentPage*pageSize >= posts.length}
                onClick={() => handlePagination(currentPage + 1)} 
                />
            </Pagination>
          </Col>
          <Col style={styles.colSidebar}
            xl={{span: 3, offset:0}}
            lg={{span: 3, offset:0}}
            md={{span: 12, offset:0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <SidebarMenu />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ListNews
