import React from 'react'
import {
  Accordion,
  Card,
  ListGroup
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import Categories from '../infrastructure/Categories'

const styles = {
  accordion: {
    width: '90%',
    border: 0
  },
  card: {
    border: 0
  },
  sidebarTitle: {
    paddingLeft: 0,
    backgroundColor: themeColors.background,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 'solid 2px',
    color: themeColors.primary,
    fontWeight:'normal',
    fontSize: '20px',
    textDecoration: 'none'
  },
  sidebarMenu: {
    backgroundColor: themeColors.background,
    paddingLeft: 10,
    borderWidth:0,
    color: themeColors.secondary,
    fontsize: '16px',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    border: 0
  }
}

const SidebarMenuDE = () => {
  return (
    <>
    <Accordion defaultActiveKey="0" style={styles.accordion}>
      <Card style={styles.card}>
        <Accordion.Toggle as={Card.Header} eventKey="0" style={styles.sidebarTitle}>
          <a href="#" >Über uns</a>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body style={styles.sidebarMenu}>
            <ListGroup style={{}}>
              <ListGroup.Item action href="/greetings-de" style={styles.sidebarMenu}>
                Grußwort
              </ListGroup.Item>
              <ListGroup.Item action href="/services-de" style={styles.sidebarMenu}>
                Aufgabe
              </ListGroup.Item>
              <ListGroup.Item action href="/contact-de" style={styles.sidebarMenu}>
                Anfahrt
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card style={styles.card}>
        <Accordion.Toggle href="#" as={Card.Header} eventKey="1" style={styles.sidebarTitle}>
          <a href={`/${Categories.keidAnnouncementDE}`} >Aktuelles</a>
        </Accordion.Toggle>
      </Card>
      <Card style={styles.card}>
        <Accordion.Toggle as={Card.Header} eventKey="2" style={styles.sidebarTitle}>
          <a href={`/${Categories.koreanischAG}`} >Koreanisch AG</a>
        </Accordion.Toggle>
      </Card>
      <Card style={styles.card}>
        <Accordion.Toggle as={Card.Header} eventKey="3" style={styles.sidebarTitle}>
          <a href={`/${Categories.koreanischKurse}`} >Koreanisch-Kurse</a>
        </Accordion.Toggle>
      </Card>
      <Card style={styles.card}>
        <Accordion.Toggle as={Card.Header} eventKey="4" style={styles.sidebarTitle}>
          <a href={`/${Categories.studiumInKorea}`} >Studium in Korea (GKS)</a>
        </Accordion.Toggle>
      </Card>
      <Card style={styles.card}>
        <Accordion.Toggle as={Card.Header} eventKey="5" style={styles.sidebarTitle}>
          <a href={`/${Categories.topikDE}`} >TOPIK</a>
        </Accordion.Toggle>
      </Card>
    </Accordion>
    </>
  )
}

export default SidebarMenuDE
