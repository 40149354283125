const themeColors = {
  primary: '#F37335',
  secondary: '#575757',
  body: '#2A2A2A',
  button: '#F37335',
  background: '#FAFAFB',
  line: '#aeaeae',
}

export {
  themeColors
}