import React from 'react'
import {
  Col,
  Dropdown,
  Image,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Row,
} from 'react-bootstrap'
import {
  Link,
  } from 'react-router-dom'
import Categories from '../infrastructure/Categories'

const styles = {
  menuLink: {
    paddingRight: 30,
  },
  mainMenu: {
    fontSize: '16px',
    fontWeight: '900',
    color: '#575757'
  },
  buttonMenu: {
    fontSize: '14px', 
    fontWeight: '900',
    // backgroundColor: '#767676',
    color: '#575757',
    marginBottom: '2px',
    border: 'solid 1px #575757',
    borderRadius: '0.25rem'
  },
  subMenu: {
    backgroundColor: '#575757',
    color: '#575757'
  }
}


const Header = () => {

  return (
      <Row>
        {/* <Col md={4}>
          <Link to="/">

          </Link>
        </Col> */}
        <Col md={12}>
          <Navbar expand="lg" >
            <Navbar.Brand href="/"><Image src="/images/keid_logo_header.png" width="200px" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" style={{zIndex: 99, backgroundColor:'#FFF'}}>
              {/* <Nav className="justify-content-center" style={{lineHeight: '40px', paddingRight: 0}} > */}
              <Nav activeKey="/home" style={{alignItems: 'flex-end'}}>
                <Dropdown as={NavItem} >
                  <Dropdown.Toggle as={NavLink} style={styles.mainMenu}>교육원소개</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="1.1" as={Link} to={`/${Categories.keidAnnouncement}`}>교육원 공지사항</Dropdown.Item>
                    <Dropdown.Item eventKey="1.2" as={Link} to={`/${Categories.keidNews}`}>교육원 새소식</Dropdown.Item>
                    <Dropdown.Item eventKey="1.3" as={Link} to="/greetings">교육원장 인사말</Dropdown.Item>
                    <Dropdown.Item eventKey="1.4" as={Link} to="/services">교육원 주요 업무</Dropdown.Item>
                    <Dropdown.Item eventKey="1.5" as={Link} to="/history">교육원 연혁</Dropdown.Item>
                    <Dropdown.Item eventKey="1.6" as={Link} to="/contact">오시는 길</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink} style={styles.mainMenu}>한글학교</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="2.1" as={Link} to="/keid-school-info">한글학교 소개</Dropdown.Item>
                    <Dropdown.Item eventKey="2.2" as={Link} to={`/${Categories.koreanSchoolAnnouncement}`}>한글학교 공지사항</Dropdown.Item>
                    <Dropdown.Item eventKey="2.2" as={Link} to={`/${Categories.koreanSchoolNews}`}>한글학교 새소식</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink} style={styles.mainMenu}>한국어보급</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="3.1" as={Link} to={`/${Categories.koreanLanguageAdoption}`}>독일 초중등학교 한국어 채택</Dropdown.Item>
                    <Dropdown.Item eventKey="3.2" as={Link} to={`/${Categories.koreanClass}`}>한국어강좌</Dropdown.Item>
                    <Dropdown.Item eventKey="3.3" as={Link} to={`/${Categories.koreanTest}`}>한국어능력시험 (TOPIK)</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink} style={styles.mainMenu}>독일교육</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="4.1" as={Link} to={`/${Categories.germanEducationSystem}`}>독일 교육제도</Dropdown.Item>
                    <Dropdown.Item eventKey="4.2" as={Link} to={`/${Categories.germanEducationInfo}`}>독일교육 소식</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink} style={styles.mainMenu}>한국유학</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="4.1" as={Link} to={`/${Categories.studyInKoreaInfo}`}>한국유학 정보(GKS) - Study in Korea</Dropdown.Item>
                    <Dropdown.Item eventKey="4.2" as={Link} to={`/${Categories.studyInKoreaAnnouncement}`}>한국유학 공지</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink} style={styles.buttonMenu}>DEUTSCH</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="5.1" as={Link} to={`/greetings-de`}>Grußwort</Dropdown.Item>
                    <Dropdown.Item eventKey="5.1" as={Link} to={`/services-de`}>Aufgabe</Dropdown.Item>
                    <Dropdown.Item eventKey="5.1" as={Link} to={`/contact-de`}>Anfahrt</Dropdown.Item>
                    <Dropdown.Item eventKey="5.1" as={Link} to={`/${Categories.keidAnnouncementDE}`}>Aktuelles</Dropdown.Item>
                    <Dropdown.Item eventKey="5.2" as={Link} to={`/${Categories.koreanischAG}`}>Koreanisch AG</Dropdown.Item>
                    <Dropdown.Item eventKey="5.2" as={Link} to={`/${Categories.koreanischKurse}`}>Koreanisch-Kurse</Dropdown.Item>
                    <Dropdown.Item eventKey="5.2" as={Link} to={`/${Categories.studiumInKorea}`}>Studium in Korea (GKS)</Dropdown.Item>
                    <Dropdown.Item eventKey="5.2" as={Link} to={`/${Categories.topikDE}`}>TOPIK</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
              <div className="gcse-search"></div>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
  )
}

export default Header

