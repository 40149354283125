import React, {
  useEffect,
  useState
} from 'react'
import {
  Link
} from 'react-router-dom'
import {
  Row,
  Col,
  Button,
  Spinner,
  ListGroup
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import Categories from "../infrastructure/Categories";
import { getPageContents } from '../infrastructure/ApisHandler'
import { signedIn } from '../infrastructure/TokenHelper'

const styles = {
  body: {
    marginBottom: '0rem',
    paddingTop: '0rem',
    paddingBottom: '1rem'
  },
  pageRow: {
    marginTop: '2rem',
    marginBottom: '3rem',
  },
  card: {
    margin: 'auto 0px',
    padding: 'auto 0px',
    borderWidth: 0,
    borderRadius: '20px 0px 20px 0px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    padding: '0rem 0rem',
    textAlign: 'left',
    fontWeight: 'bold',
    fontColor: '#2B2B2B',
    fontSize: '20px'
  },
  cardBody: {
    margin: 'auto 0px',
    // paddingLeft: '0px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'medium',
    color: '#2B2B2B'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    borderWidth:0,
    padding: '0.25rem 0rem',
    backgroundColor: "#FAFAFB"
  },
  content: {
    fontSize: '14px',
    fontWeight: '300',
    color: '#2B2B2B',
  },
  info: {
    marginBottom: -3,
    paddingBottom: 0,
    fontSize: '11px',
    fontWeight: '300',
    color: '#155799',
  },
  button: {
    width: 130,
    backgroundColor: themeColors.button,
    color: '#FFF',
    borderWidth: 0,
    borderRadius: 20,
    marginRight: 20,
  }
}

const ListSchools = ({category}) => {
  const [spinning, setSpinning] = useState(false)
  const [schools, setSchools] = useState([])
  const [dividCount, setDividCount] = useState(15)
  useEffect(() => {
    (async () => {
      setSpinning(true)

      const json = await getPageContents(Categories.keidSchoolInfo)
      setSchools(json.data || [])
      // setDividCount(schools.length / 2)
      
      setSpinning(false)
    })()
  }, [])

  return (
    <>  
      {
        (spinning) &&
          <Spinner
            variant="warning"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            style={{zIndex: 999, position: 'absolute', top: '50%', left: '50%', marginRight:'10px'}}
          />
      }
      <Row>
        <Col md={6}>
          <ListGroup>
            {
              (schools) &&
                  schools.map((x,i) => (
                    (i <= dividCount) &&
                    <ListGroup.Item style={styles.listItem} key={i}>
                        <Link to={`/${x.category.value}/${x.contentId.value}`} >
                          <span style={styles.content}>{x.title}</span>
                        </Link>
                    </ListGroup.Item>
                  ))
            }
          </ListGroup>
        </Col>
        <Col md={6}>
          <ListGroup>
            {
              (schools) &&
                  schools.map((x,i) => (
                    (i > dividCount) &&
                    <ListGroup.Item style={styles.listItem} key={i}>
                        <Link to={`/${x.category.value}/${x.contentId.value}`} >
                          <span style={styles.content}>{x.title}</span>
                        </Link>
                    </ListGroup.Item>
                  ))
            }
          </ListGroup>
        </Col>
      </Row>
      <Row style={styles.pageRow}>
        <Col md={{span:3}} >
          {
            signedIn() && 
            <Button style={styles.button} href="/write-content">신규학교 등록</Button>
          }
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
    </>
  )
}

export default ListSchools
