import React from 'react'
import {
  Row,
  Col,
  Container,
} from 'react-bootstrap'
import 'react-alice-carousel/lib/alice-carousel.css';

const styles = {
  banner: {
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  image: {
    margin: '20px 0px 20px 0px',
    padding: 'auto 0px',
    width: '160px',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
}

const handleDragStart = (e) => e.preventDefault();

const items = [
  <a href="https://www.moe.go.kr/main.do" rel="noreferrer" target="_blank">
    <img src="/images/org-01-moe.jpg" 
      onDragStart={handleDragStart} 
      className="yours-custom-class" 
      alt="교육부"
      style={styles.image}
      />
  </a>,
  <a href="https://overseas.mofa.go.kr/de-frankfurt-ko/index.do" rel="noreferrer" target="_blank">
    <img src="/images/org-02-moefr.jpg" 
      onDragStart={handleDragStart} 
      className="yours-custom-class" 
      alt="주프랑크푸르트 대한민국 영사관" 
      style={styles.image}
      />
  </a>,
  <a href="http://www.niied.go.kr/main/main.do" rel="noreferrer" target="_blank">
    <img src="/images/org-03-kiied.jpg" 
      onDragStart={handleDragStart} 
      className="yours-custom-class" 
      alt="교육부 국립국제교육원" 
      style={styles.image}
      />
  </a>,
  <a href="https://www.korea.net/main" rel="noreferrer" target="_blank">
    <img src="/images/org-04-konet.jpg"
      onDragStart={handleDragStart} 
      className="yours-custom-class" 
      alt="코리아넷" 
      style={styles.image}
      />
  </a>,
  <a href="http://study.korean.net/servlet/action.home.MainAction" rel="noreferrer" target="_blank">
    <img src="/images/org-05-sk.jpg"
      onDragStart={handleDragStart} 
      className="yours-custom-class" 
      alt="Study Korean" 
      style={styles.image}
      />
  </a>,
  <a href="https://www.studyinkorea.go.kr/en/main.do;jsessionid=ZU1vIgCgI68R5vyz5YBGNuWneeJYEkDvOxge4EmE.studyinkorea10" rel="noreferrer" target="_blank">
    <img src="/images/org-06-sink.jpg"
      onDragStart={handleDragStart} 
      className="yours-custom-class" 
      alt="Study in Korea" 
      style={styles.image}
      />
  </a>,
  <a href="http://okep.moe.go.kr/root/index.do" rel="noreferrer" target="_blank">
    <img src="/images/org-11-koedu.jpg"
      onDragStart={handleDragStart} 
      className="yours-custom-class" 
      alt="재외교육기관포털" 
      style={styles.image}
      />
  </a>,
  <a href="https://www.topik.go.kr/usr/cmm/index.do" rel="noreferrer" target="_blank">
    <img src="/images/org-12-topik.jpg"
      onDragStart={handleDragStart} 
      className="yours-custom-class" 
      alt="TOPIK" 
      style={styles.image}
      />
  </a>
];

const AffiliatedBanner = () => {

  return (
    <Container>
      <Row style={styles.banner}>
          {items.map((x,i) => <Col key={i} md={3} sm={6} style={{textAlign:'center'}}>{x}</Col>)}
      </Row>
    </Container>
  )
}

export default AffiliatedBanner
