import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap'
import {
  Link
  } from 'react-router-dom'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import { themeColors } from '../Styles'

const styles = {
  body: {
    marginBottom: '0rem',
    paddingTop: '40px',
    paddingBottom: '0rem'
  },
  pageRow: {
    marginBottom: '3rem',
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: 'auto',
    // borderWidth: 0,
    borderRadius: '10px 10px 10px 10px',
    marginBottom: '2rem'
  },
  imageBox: {
    position: 'relative',
    overflow: 'hidden',
    width: '240px',
    height: '160px',
    border: 'solid 0px #FFFFFF',
    // borderRadius: '10px 0px 0px 10px'
  },
  thumbnail: (thumbnailUrl) => {
    return({
      border: 'solid 0px #FFFFFF',
      borderRadius: '10px 0px 0px 10px',
      borderWidth: 0,
      width: '240px',
      height: '160px',
      backgroundSize: 'cover',
      backgroundImage: (thumbnailUrl) 
        ? `url(${thumbnailUrl})` 
        : `url(/images/KEID-thum.png)`, 
      content: `url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')`
    })
  },
  title: {
    marginBottom: 0,
    fontSize: '18px',
    fontWeight: 'bold',
  },
  content: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#575757'
  },
  info: {
    fontSize: '12px',
    color: '#155799'
  },
  button: {
    width: 130,
    backgroundColor: themeColors.button,
    color: '#FFF',
    borderWidth: 0,
    borderRadius: 20,
    marginRight: 20,
  }
}

const GalleryItems = ({posts}) => {
  const [userSignedIn, setUserSignedIn] = useState(false)
  let [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {   
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    (async () => {
      const idToken = localStorage.getItem('keid_user_token')
      if (idToken) {
        const decoded = jwt_decode(idToken)
        const notExpired = Date.now() < decoded.exp * 1000
        setUserSignedIn(notExpired)
      }
    })()
  }, [])

  return (
    <>
      <Row style={styles.body}>
        {posts.map((x,i) => (
          <Col md={12} key={i}>
            <Link to={`/${x.category}/${x.postId}`} style={{textDecoration: 'none'}}>
              <Card style={styles.card}>
                {/* <Card.Img variant="top" src={x.attachments[0].objectUrl} style={{borderRadius: '10px 10px 0px 0px' }} /> */}
                <Card.Img variant="top" style={styles.thumbnail(x.thumbnailUrl)} />
                <Card.Body>
                  <Card.Title className="primary-title" style={styles.title}>
                    {
                      (size[0]>=1200) 
                      ? `${x.title.substring(0, 35)}`  
                      : (size[0]>992)
                        ? `${x.title.substring(0, 22)}`
                        : (size[0]>768)
                          ? `${x.title.substring(0, 12)}`
                          : (size[0]>576)
                            ? `${x.title.substring(0, 12)}`
                            : (size[0]>374)
                              ? `${x.title.substring(0, 10)} ...`
                              : `${x.title.substring(0, 2)} ...`
                    }
                  </Card.Title>
                  <Card.Text style={styles.info}>
                    <small>{moment(x.dateTimestamp).format('YYYY-MM-DD')}</small>
                  </Card.Text>
                  <Card.Text style={styles.content}>
                    {x.excerpt.substring(0, 
                      (size[0]>=1200) 
                        ? x.excerpt.length
                        : (size[0]>992)
                          ? x.excerpt.length
                          : (size[0]>768)
                            ? x.excerpt.length/2
                            : (size[0]>576)
                              ? x.excerpt.length/3
                              : (size[0]>374)
                                ? x.excerpt.length/4
                                : ''
                      )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
      <Row style={styles.pageRow}>
        <Col md={{span:3}} >
          {
            userSignedIn &&
            <Button style={styles.button} href="/write-post">새 글쓰기</Button>
          }
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      {/* <Row>
        <Col md={12} style={{textAlign: 'center'}}>
          <Pagination size="sm" style={{display: 'inline-flex', paddingRight: '15px'}}>
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />

            <Pagination.Item>{10}</Pagination.Item>
            <Pagination.Item>{11}</Pagination.Item>
            <Pagination.Item active>{12}</Pagination.Item>
            <Pagination.Item>{13}</Pagination.Item>
            <Pagination.Item disabled>{14}</Pagination.Item>

            <Pagination.Ellipsis />
            <Pagination.Item>{20}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last />
          </Pagination>
        </Col>
      </Row> */}
  </>
  )
}

export default GalleryItems
