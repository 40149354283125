import React, {useState} from 'react'
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap'
import LatestNews from '../components/LatestNews'
import Education from '../components/Education'
import AffiliatedBanner from '../components/AffiliatedBanner'
import LatestAnnouncements from '../components/LatestAnnouncements'

import ImageSlider from '../components/ImageSlider'
import WebsiteNotice from '../components/WebsiteNotice'
import PopupCard from '../components/PopupCard'

const styles = {
  headerContainer: {
    marginBottom: '1rem',
    padding: 0
  },
  mainContainer: {
    padding: '3rem 1% 1rem 2%'
  },
  mainContainerWhite: {
    padding: '1.5rem 1% 1.5rem 2%',
    backgroundColor: "#FFF"
  },
  sectionTitle: {
    textAlign: 'center', 
    fontFamily: 'Gosanja',
    fontWeight: '300', 
    fontSize: '32px', 
    color: '#575757' 
  },
  button: {
    marginTop: '1rem',
    // width:'192px',
    borderRadius:50,
    fontSize: '1.5vw',
    fontWeight: 'bold',
    color: '#FFFFFF',
    boxShadow: '0 2px 10px 0 rgba(255, 160, 72, 0.4), 0 4px 15px 0 rgba(255, 160, 72, 0.2)'
  },
  buttonSpring: (size) => {
    return ({
    zIndex: 9999,
    position: 'absolute',
    top: size[1]-100,
    right: 10, //window.innerWidth/2,
    width: 50,
    height: 50,
    borderRadius:50,
    fontSize: '30px',
    fontWeight: 'normal',
    color: '#FFF',
    opacity: '90%',
    borderWidth: 0
    })
  },
  closePopup: (closed) => {
    return {
      visibility: closed ? 'hidden' : 'inherit'
    }
  }
}

const homeSlides = [
  {
    image: "/images/main-banner-01.jpg",
    taglines: [
      "주독일한국교육원은 재외국민의 교육활동 지원을 위해 설립된 기관으로,", 
      "독일 내 한글학교의 교육활동 지원, 한국어 보급 사업, 한독 교육교류 지원 등을 주요 업무로 하고 있습니다."
    ],
    title: "주독일한국교육원에 오신 것을 환영합니다."
  },
  {
    image: "/images/main-banner-02.jpg",
    taglines: [
      "주독일한국교육원은 재외국민의 교육활동 지원을 위해 설립된 기관으로,", 
      "독일 내 한글학교의 교육활동 지원, 한국어 보급 사업, 한독 교육교류 지원 등을 주요 업무로 하고 있습니다."
    ],
    title: "주독일한국교육원에 오신 것을 환영합니다."
  },
  {
    image: "/images/main-banner-03.jpg",
    taglines: [
      "주독일한국교육원은 재외국민의 교육활동 지원을 위해 설립된 기관으로,", 
      "독일 내 한글학교의 교육활동 지원, 한국어 보급 사업, 한독 교육교류 지원 등을 주요 업무로 하고 있습니다."
    ],
    title: "주독일한국교육원에 오신 것을 환영합니다."
  },
  {
    image: "/images/main-banner-04.jpg",
    taglines: [
      "주독일한국교육원은 재외국민의 교육활동 지원을 위해 설립된 기관으로,", 
      "독일 내 한글학교의 교육활동 지원, 한국어 보급 사업, 한독 교육교류 지원 등을 주요 업무로 하고 있습니다."
    ],
    title: "주독일한국교육원에 오신 것을 환영합니다."
  }
]

const Home = () => {
  const [noticeClosed, setNoticeClosed] = useState(false)
  const [popupClosed, setPopupClosed] = useState(true)

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      <Container fluid style={styles.headerContainer}>
        <ImageSlider slides={homeSlides} />
        {(!popupClosed) && 
          <PopupCard
            title=""
            contents=""
            url="/"
            onClose={setPopupClosed}
            style={styles.closePopup(popupClosed) }
          />
        }
        {(!noticeClosed) &&
          <WebsiteNotice onClose={setNoticeClosed}
            style={styles.closePopup(noticeClosed) }
          />
        }
      </Container>

      <Container style={styles.mainContainer}>
        <Row>
          <Col md={12} style={styles.sectionTitle}>
            <p>
              주독일한국교육원의 <font className="primary-title" >소식과 정보</font>
            </p>
          </Col>
        </Row>
        <Row>
          <LatestAnnouncements />
          <LatestNews />
        </Row>
      </Container>

      <Container fluid style={styles.mainContainer}>
        <Education />
      </Container>

      <Container fluid style={styles.mainContainerWhite}>
        <AffiliatedBanner />
      </Container>
    </div>
  )
}

export default Home
