import React from 'react'
import {
  Container,
  Row,
  Col,
  Image
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import PageTitleBanner from '../components/PageTitleBanner'
import SidebarMenuDE from '../components/SidebarMenuDE'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  row: {
    marginBottom: '25px'
  },
  colLeft: {
    marginTop: '25px',
    paddingBottom: '7%',
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingBottom: '7%',

  },
  colCenter: {
    marginTop: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '7%',
  },
  imageColumnRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  },
  sectionTag: {
    marginBottom: '0px',
    color: themeColors.primary,
    textAlign: 'center'
  },
  sectionTitle: {
    marginBottom: '50px',
    color: '#2B2B2B',
    fontFamily: 'Gosanja',
    fontWeight: '300', 
    fontSize: '32px',
    textAlign: 'center'
  },
  sectionSubtitle: {
    color: '#575757',
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'left',
  },
  sectionDescription: {
    color: '#575757',
    fontSize: '14px',
    textAlign: 'left',
  },
  historyDetail: {
    color: themeColors.primary,
    fontSize: '14px'
  },
  button: {
    width:'192px',
    borderRadius:50,
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  card: {
    height: '250px',
    marginBottom: '10px',
    borderWidth: 0,
    borderRadius: '7px 7px 7px 7px',
    boxShadow: '0 2px 10px 0 rgba(21, 87, 153, 0.1), 0 4px 15px 0 rgba(21, 87, 153, 0.05)'
  },
  cardSubtitle: {
    fontSize: '19px',
    color: '#2B2B2B',
    fontWeight: 'bold'
  }
  ,
  cardText: {
    fontSize: '14px',
    color: '#575757',
    fontWeight: 'normal'
  }
}

const ContactDE = () => {
  document.title = 'Anfahrt'

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner
          imageUrl="/images/title-banner-bg-o.png"
          title="Anfahrt"
        />
      </Container>
      <Container style={styles.mainContainer} id="services">
        <Row style={styles.row}>
          <Col>
          {/* <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4963.086138336348!2d8.620484404210933!3d50.08315986344074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0ba4ba5eac8b%3A0xb16e24ae80150ed8!2sLyoner%20Str.%2034%2C%2060528%20Frankfurt%20am%20Main%2C%20Germany!5e0!3m2!1sen!2suk!4v1612478301243!5m2!1sen!2suk" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
          <Image src="/images/keid-map.jpg" style={{width: '100%'}} />
          </Col>
        </Row>

        <Row style={styles.row}>
          <Col style={styles.colLeft}
            xl={{span: 3, offset: 0}}
            lg={{span: 10, offset: 1}}
            md={{span: 10, offset: 1}}
            sm={{span: 10, offset: 1}}
            xs={{span: 10, offset: 1}}
          >
            <p style={styles.sectionTag}>Koreanisches Erziehungsinstitut in Deutschland</p>
            <h1 style={styles.sectionTitle}>Anschrift</h1>
            <h5 style={styles.sectionSubtitle}>Kontakt</h5>
            <p style={styles.sectionDescription}>
              Tel ㅣ 069-95675231 <br />
              Fax ㅣ 069-56003987 <br />
              email ㅣ keid@mofa.or.kr
            </p>
            <h5 style={styles.sectionSubtitle}>Adresse</h5>
            <p style={styles.sectionDescription}>
              OLIVETTI (Turm 2/13. OG) <br />
              Lyoner Str. 34, 60528 Frankfurt am Main
            </p>
            <h5 style={styles.sectionSubtitle}>Öffnungszeiten</h5>
            <p style={styles.sectionDescription}>
              Montag - Freitag<br />
              09:00 - 12:00 Uhr, <br />
              14:00 - 17:00 Uhr <br />
            </p>
          </Col>
          <Col style={styles.colCenter}
            xl={{span: 4, offset: 1}}
            lg={{span: 10, offset: 1}}
            md={{span: 10, offset: 1}}
            sm={{span: 10, offset: 1}}
            xs={{span: 10, offset: 1}}
          >
            <p style={styles.sectionTag}>Addresse und Routenplaner</p>
            <h1 style={styles.sectionTitle}>Wegbeschreibung</h1>
            <h5 style={styles.sectionSubtitle}>Mit öffentlichen Verkehrsmitteln</h5>
            <p style={styles.sectionDescription}>
              S8, S9 bis zum Bahnhof Niederrad, anschließend mit der Tram 12 (Richtung Rheinlandstraße)
              oder den Bussen 78 und 79 (Richtung Lyonerstraße), bis zur Haltestelle Bürostadt Niederrad fahren.
              Dann entlang der Lyoner Straße bis zum Zwillingsgebäude(Olivetti Turm) laufen.
            </p>
            <h5 style={styles.sectionSubtitle}>Mit dem Auto</h5>
            <p style={styles.sectionDescription}>
              A 5 Darmstadt - Frankfurt an der Anschlussstelle Frankfurt-Niederrad (21) in Richtung Uniklinik,
              Niederrad verlassen und dann rechts in das Schwanheimer Ufer einbiegen.
              Dann das Schwanheimer Ufer in Richtung Niederrad Bürostadt verlassen
              und auf die Lyoner Straße weiter bis zum Zwillingsgebäude (Olivetti Turm) fahren
            </p>
          </Col>
          <Col style={styles.colSidebar}
            xl={{span: 3, offset: 0}}
            lg={{span: 10, offset: 1}}
            md={{span: 10, offset: 1}}
            sm={{span: 10, offset: 1}}
            xs={{span: 10, offset: 1}}
          >
            <SidebarMenuDE />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContactDE
