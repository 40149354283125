import './styles.scss'

import {
  Container,
} from 'react-bootstrap'

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom"

import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import ListNews from './pages/ListNews'
import WritePost from './pages/WritePost'
import SignInCallback from './pages/SignInCallback'
import Greetings from './pages/Greetings'
import GreetingsDE from './pages/GreetingsDE'
import History from './pages/History'
import Services from './pages/Services'
import ServicesDE from './pages/ServicesDE'
import Contact from './pages/Contact'
import ContactDE from './pages/ContactDE'
import SchoolPortal from './pages/SchoolPortal'
import { ToastProvider } from 'react-toast-notifications'
import ViewPost from './pages/ViewPost'
import ViewPostDE from './pages/ViewPostDE'
import ListPosts from './pages/ListPosts'
import ListPostsDE from './pages/ListPostsDE'
import Categories from './infrastructure/Categories'
import ViewPageContent from './pages/ViewPageContent'
import WriteContent from './pages/WriteContent'

function App() {

  return (
    <Router>
      <ToastProvider>
        <Container fluid style={{padding: '0rem 2%', }}>
          <Header />
        </Container>

        <Switch>
          <Route path="/sign-in-callback"><SignInCallback /></Route>

          <Route exact path="/write-content"><WriteContent /></Route>
          <Route exact path="/write-content/:category/:id"><WriteContent /></Route>
          <Route exact path="/write-post"><WritePost /></Route>
          <Route exact path="/write-post/:id" component={WritePost} />
          <Route exact path="/posts/:id" component={ViewPost} />

          <Route exact path="/keid-school-info"><SchoolPortal /></Route>
          <Route exact path={`/${Categories.keidSchoolInfo}/:id`}>
            <ViewPageContent category={`${Categories.keidSchoolInfo}`} />
          </Route>

          {/* display the first page content with id */}
          <Route exact path={`/${Categories.studyInKoreaInfo}`}>
            <ViewPageContent category={`${Categories.studyInKoreaInfo}`} />
          </Route>
          <Route exact path={`/${Categories.studiumInKorea}`}>
            <ViewPageContent category={`${Categories.studiumInKorea}`} />
          </Route>

          <Route exact path={`/${Categories.keidAnnouncementDE}/:id`}>
            <ViewPostDE category={`${Categories.keidAnnouncementDE}`} />
          </Route>
          <Route exact path={`/${Categories.koreanischAG}/:id`}>
            <ViewPostDE category={`${Categories.koreanischAG}`} />
          </Route>
          <Route exact path={`/${Categories.koreanischKurse}/:id`}>
            <ViewPostDE category={`${Categories.koreanischKurse}`} />
          </Route>
          <Route exact path={`/${Categories.topikDE}/:id`}>
            <ViewPostDE category={`${Categories.topikDE}`} />
          </Route>
          
          
          <Route exact path={`/${Categories.studyInKoreaAnnouncement}/:id`}>
            <ViewPost category={`${Categories.studyInKoreaAnnouncement}`} />
          </Route>
          <Route exact path={`/${Categories.germanEducationSystem}/:id`}>
            <ViewPost category={`${Categories.germanEducationSystem}`} />
          </Route>
          <Route exact path={`/${Categories.germanEducationInfo}/:id`}>
            <ViewPost category={`${Categories.germanEducationInfo}`} />
          </Route>
          <Route exact path={`/${Categories.koreanLanguageAdoption}/:id`}>
            <ViewPost category={`${Categories.koreanLanguageAdoption}`} />
          </Route>
          <Route exact path={`/${Categories.koreanClass}/:id`}>
            <ViewPost category={`${Categories.koreanClass}`} />
          </Route>
          <Route exact path={`/${Categories.koreanTest}/:id`}>
            <ViewPost category={`${Categories.koreanTest}`} />
          </Route>
          <Route exact path={`/${Categories.koreanSchoolAnnouncement}/:id`}>
            <ViewPost category={`${Categories.koreanSchoolAnnouncement}`} />
          </Route>
          <Route exact path={`/${Categories.koreanSchoolNews}/:id`}>
            <ViewPost category={`${Categories.koreanSchoolNews}`} />
          </Route>
          <Route exact path={`/${Categories.keidNews}/:id`}>
            <ViewPost category={`${Categories.keidNews}`} />
          </Route>
          <Route exact path={`/${Categories.keidAnnouncement}/:id`}>
            <ViewPost category={`${Categories.keidAnnouncement}`} />
          </Route>


          <Route exact path={`/${Categories.keidAnnouncementDE}`}>
            <ListPostsDE category={`${Categories.keidAnnouncementDE}`} />
          </Route>
          <Route exact path={`/${Categories.koreanischAG}`}>
            <ListPostsDE category={`${Categories.koreanischAG}`} />
          </Route>
          <Route exact path={`/${Categories.koreanischKurse}`}>
            <ListPostsDE category={`${Categories.koreanischKurse}`} />
          </Route>
          <Route exact path={`/${Categories.topikDE}`}>
            <ListPostsDE category={`${Categories.topikDE}`} />
          </Route>

          <Route exact path={`/${Categories.studyInKoreaAnnouncement}`}>
            <ListPosts category={`${Categories.studyInKoreaAnnouncement}`} />
          </Route>

          <Route exact path={`/${Categories.germanEducationSystem}`}>
            <ListPosts category={`${Categories.germanEducationSystem}`} />
          </Route>
          <Route exact path={`/${Categories.germanEducationInfo}`}>
            <ListPosts category={`${Categories.germanEducationInfo}`} />
          </Route>

          <Route exact path={`/${Categories.koreanLanguageAdoption}`}>
            <ListPosts category={`${Categories.koreanLanguageAdoption}`} />
          </Route>
          <Route exact path={`/${Categories.koreanClass}`}>
            <ListPosts category={`${Categories.koreanClass}`} />
          </Route>
          <Route exact path={`/${Categories.koreanTest}`}>
            <ListPosts category={`${Categories.koreanTest}`} />
          </Route>

          <Route exact path={`/${Categories.koreanSchoolAnnouncement}`}>
            <ListPosts category={`${Categories.koreanSchoolAnnouncement}`} />
          </Route>

          <Route exact path={`/${Categories.keidAnnouncement}`}>
            <ListPosts category={`${Categories.keidAnnouncement}`} />
          </Route>

          <Route exact path={`/${Categories.koreanSchoolNews}`}>
            <ListNews category={`${Categories.koreanSchoolNews}`} />
          </Route>
          <Route exact path={`/${Categories.keidNews}`}>
            <ListNews category={`${Categories.keidNews}`} />
          </Route>

          <Route path="/greetings-de"><GreetingsDE /></Route>
          <Route path="/services-de"><ServicesDE /></Route>
          <Route path="/contact-de"><ContactDE /></Route>
          <Route path="/greetings"><Greetings /></Route>
          <Route path="/history"><History /></Route>
          <Route path="/services"><Services /></Route>
          <Route path="/contact"><Contact /></Route>
          <Route path="/"><Home /></Route>
        </Switch>

        {/* <Container fluid style={{padding: '1rem 2% 1rem 2%', backgroundColor: "#6C839D"}}> */}
        <Container fluid style={{padding: '1rem 2% 1rem 2%', backgroundColor: "#575757"}}>
          <Footer />
        </Container>
      </ToastProvider>
    </Router>
  )
}

export default App
