import React, {useLayoutEffect, useState} from 'react'
import {useSpring} from 'react-spring'
import {
  Row,
  Col,
  Image,
  Container
} from 'react-bootstrap'
import {
  useLocation,
  } from 'react-router-dom'
import config from '../config.json'

const styles = {
  row: {
    marginToop: '2rem',
    paddingTop: '2%',
    paddingBottom: '2%',
    // textAlign: 'center'
  },
  address: {
    marginTop: '0px',
    marginBottom: '0px',
    fontSize: '14px', 
    color: '#FFFFFF'
  },
  title: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#FFFFFF'
  },
  text: {
    marginBottom: '0px',
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#FFFFFF'
  },
  map: {
    textAlign: 'right'
  },
  copyright: {
    marginTop: '10px',
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#FFFFFF'
  },
  social: {
    marginRight: '5px',
    textAlign: 'right',
    fontSize: '2.2rem',
    fontWeight: 'normal',
    color: '#FFFFFF'
  },
  buttonSpring: (size) => {
    return ({
      zIndex: 99999,
      position: 'absolute',
      bottom: '2%',
      right: 25,
      width: 50,
      height: 50,
      borderRadius:50,
      fontSize: '40px',
      fontWeight: '300',
      color: '#575757',
      backgroundColor: '#FFFFFF',
      borderWidth: 0,
      // boxShadow: '1px 3px 10px rgba(118, 118, 118, 0.8)',
      transition: 'all 0.3s ease 0s',
      cursor: 'pointer',
      outline: 'none',
    })
  },
}

// const marginedText = Object.assign(
//   {marginLeft: '20px'},
//   styles.text
//   )


const Footer = () => {
  const [y, setY] = useSpring(() => ({
    immediate: false,
    y: 0,
    onFrame: props => {
      window.scroll(0, props.y);
    },
    config: {duration: 250},
  }));

  let [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {   
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Row style={styles.row}>
      <Col md={12}>
        <Container>
          <Row>
            <Col md={4}>
              <Image src="/images/keid_logo_wt.png" rounded style={{width:'230px', margin: 0, padding: 0}}/>
              <p style={styles.copyright}>(C) Copyrights 2020 KEID.OR.KR All rights reserved</p>
            </Col>
            <Col md={4} style={styles.title}>
              <p style={styles.address}>
                Koreanisches Erziehungsinstitut in Deutschland<br /> 
                OLIVETTI (Turm 2/13. OG)<br /> 
                Lyoner Str. 34, 60528<br /> 
                Frankfurt am Main
              </p>
            </Col>
            <Col md={4} style={styles.title}>
              <p style={styles.address}>
                T. +49 (0)69 9567 5231<br /> 
                E. keid@mofa.or.kr
              </p>
              {/* <span><i className="fab fa-facebook-square fa-sm" style={styles.social}></i></span>
              <span><i className="fab fa-linkedin fa-sm" style={styles.social}></i></span> */}
              {/* <span> */}
                <a href='https://www.instagram.com/keid.de' target='_black'>
                  <i className="fab fa-instagram-square fa-sm" style={styles.social} />
                </a>
              {/* </span> */}
              <a 
                href={`${config.AUTH_URI}?client_id=${config.CLIENT_ID}&response_type=token&scope=${config.SCOPE}&redirect_uri=${window.location.origin}/sign-in-callback&state=${useLocation().pathname}`} 
                style={styles.social}
              >
                .
              </a>
            </Col>
            <button
              variant="primary"
              onClick={() => {
                setY((y!==0) ? {y:0} : {y});
              }}

              style={styles.buttonSpring(size)}
            >
              <i className="fas fa-sort-up"></i>
            </button>
          </Row>
        </Container>
      </Col>
    </Row>
  )
}

export default Footer;
