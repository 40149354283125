import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  Row,
  Col,
  Card,
  ListGroup,
  Button,
} from 'react-bootstrap'
import {
  Link
} from 'react-router-dom'
import moment from 'moment'
import Categories from '../infrastructure/Categories'
import { getCategoryPosts } from '../infrastructure/ApisHandler'

const styles = {
  Col: {
    marginBottom: '1rem',
  },
  title: {
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  card: {
    height: '360px',
    borderWidth: 0,
    borderRadius: '25px 0px 25px 0px',
    // boxShadow: '0px 3px 15px rgba(118, 118, 118, 0.25)',
    boxShadow: '5px 9px 20px rgba(118, 118, 118, 0.45)',
  },
  ImageList: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: '0px auto 10px auto',
    borderWidth: 0,
    // borderRadius: '10px 10px 10px 10px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    padding: '0rem 1.25rem',
    textAlign: 'left',
    fontWeight: 'bold',
    fontColor: '#2B2B2B',
    fontSize: '20px',
    marginBottom: 0
  },
  listItem: {
    borderWidth:0,
    padding: '0.25rem 1.25rem',
  },
  postTitle: {
    margin: '0px auto',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  content: {
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#575757'
  },
  info: {
    marginBottom: -3,
    paddingBottom: 0,
    fontSize: '11px',
    fontWeight: '300',
    color: '#155799',
  },
  button: {
    borderRadius:50,
    width: '38px',
    fontSize: '15px',
    fontWeight: 'normal',
    color: '#FFFFFF',
  },
  thumbnailImage: (thumbnailUrl, size) => {
    return {
      width:(size[0]>=1440)?'150px':((size[0]<768)?'150px':'150px'),
      height:(size[0]>=1440)?'100px':((size[0]<768)?'100px':'100px'),
      background: 'transparent no-repeat center',
      backgroundSize: 'cover',
      backgroundImage: (thumbnailUrl) 
        ? `url(${thumbnailUrl})` 
        : `url(/images/german-edu-06.png)`, 
    }
  },
}

const LatestNews = () => {
  const [news, setNews] = useState([])
  let [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {   
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    (async () => {

      const json = await getCategoryPosts(Categories.keidNews)
      setNews(json.data || [])

    })()
  }, [])

  return (
    <Col xl={6} lg={6} md={12} style={styles.Col}>
      <Card style={styles.card}>
        <Card.Body style={{textAlign: 'left'}}>
          <Card.Title style={styles.cardHeader}>
            <span>교육원 새소식</span>
            <span>
              <Button variant="button" style={styles.button} href={`/${Categories.keidNews}`} >
                <i className="fas fa-plus" ></i>
              </Button>
            </span>
          </Card.Title>
          <hr />
          <ListGroup>
              <ListGroup.Item style={styles.listItem} > 

                  {news.map((x,i) => (
                    (i<2) &&
                    <Row>
                      <Col md={12} key={i}>
                        <Link to={`/${x.category}/${x.postId}`} style={{textDecoration: 'none'}}>
                          <Card style={styles.ImageList}>
                            <Card.Img style={styles.thumbnailImage(x.thumbnailUrl, size)}/>
                            <Card.Body style={{paddingTop: 0}}>
                              <Card.Title className="primary-title" style={styles.postTitle}>
                                {`${x.title.substring(0,
                                  (size[0]>=1200) 
                                  ? x.title.length
                                  : (size[0]>992)
                                    ? 10
                                    : (size[0]>768)
                                      ? 25
                                      : (size[0]>576)
                                        ? 15
                                        : (size[0]>374)
                                          ? 10
                                          : 2
                                  )}`}
                              </Card.Title>
                              <Card.Text style={styles.info}>
                                <small>{moment(x.dateTimestamp).format('YYYY-MM-DD')}</small>
                              </Card.Text>
                              <Card.Text style={styles.content}>
                                {x.excerpt.substring(0, 
                                  (size[0]>=1200) 
                                    ? x.excerpt.length
                                    : (size[0]>992)
                                      ? x.excerpt.length/3
                                      : (size[0]>768)
                                        ? x.excerpt.length/2+25
                                        : (size[0]>576)
                                          ? x.excerpt.length/3+10
                                          : (size[0]>374)
                                            ? x.excerpt.length/4
                                            : ''
                                  )}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                  ))}
                
              </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default LatestNews
