import React, { useLayoutEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import PageTitleBanner from '../components/PageTitleBanner'
import SidebarMenu from '../components/SidebarMenu'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  colRight: {
    alignSelf: 'flex-start',
    paddingBottom: '7%',
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    marginTop: '-14px',
    paddingBottom: '7%',
  },
  colImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '7% auto',
  },
  dynamicImage: (size) => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width:(size[0]>=2000)?'500px':((size[0]<768)?'300px':'30vw'),
      textAlign: 'center'
    }
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  },
  sectionTag: {
    marginBottom: '0px',
    color: themeColors.primary,
  },
  sectionTitle: {
    marginBottom: '25px',
    color: '#2B2B2B',
    fontFamily: 'Gosanja',
    fontWeight: '300', 
    fontSize: '32px',
  },
  sectionDescription: {
    color: '#575757',
    fontSize: '14px',
    textAlign: 'left'
  },
  historyDetail: {
    paddingLeft: '10px',
    color: '#575757',
    fontSize: '14px',
    textAlign: 'left'
  },
  button: {
    width:'192px',
    borderRadius:50,
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  card: {
    height: '250px',
    marginBottom: '10px',
    borderWidth: 0,
    borderRadius: '7px 7px 7px 7px',
    boxShadow: '0 2px 10px 0 rgba(21, 87, 153, 0.1), 0 4px 15px 0 rgba(21, 87, 153, 0.05)'
  },
  cardSubtitle: {
    fontSize: '19px',
    color: '#2B2B2B',
    fontWeight: 'bold'
  }
  ,
  cardText: {
    fontSize: '14px',
    color: '#575757',
    fontWeight: 'normal'
  },
  icon: {
    color: themeColors.primary,
  }
}


const History = () => {
  const [size, setSize] = useState([0, 0])
  document.title = "교육원 연혁"

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner
          imageUrl="/images/title-banner-bg-o.png"
          title="교육원 연혁"
        />
      </Container>
      <Container style={styles.mainContainer} >
        <Row style={styles.row}>
          <Col style={styles.colImage}
            xl={{span: 4, offset: 0}}
            lg={{span: 4, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <Image src="/images/img-history.png" style={styles.dynamicImage(size)} />
          </Col>
          <Col style={styles.colRight}
            xl={{span: 5, offset: 0}}
            lg={{span: 5, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            {/* <p style={styles.sectionTag}>주독일한국교육원</p>
            <h1 style={styles.sectionTitle}>교육원 연혁</h1> */}
            <p style={styles.sectionDescription}>주독일한국교육원은 40년이 넘는 시간 동안 독일 내 한국 문화와 한국어 보급을 위해 최선을 다해 일해왔습니다.</p>

            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>1981. 08. 04 문교부(현 교육부)설립 인가</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>1981. 08. 04 김광윤 교육원장 (제1대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>1984. 08. 04 이상경 교육원장 (제2대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>1987. 08. 10 신판식 교육원장 (제3대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>1992. 08. 10 박종화 교육원장 (제4대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>1997. 08. 10 이기용 교육원장 (제5대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>2000. 08. 10 심제택 교육원장 (제6대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>2004. 08. 10 윤인섭 교육원장 (제7대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>2008. 08. 10 노유경 교육원장 (제8대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>2011. 08. 10 홍성대 교육원장 (제9대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>2014. 08. 10 문경애 교육원장 (제10대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>2017. 08. 10 최영규 교육원장 (제11대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}>2020. 08. 21 이지숙 교육원장 (제12대)</span>
            </p>
            <p style={{marginBottom: '5px'}}>
              <i className="fas fa-medal fa-sm" style={styles.icon}></i>
              <span style={styles.historyDetail}><b>2023. 08. 21 이지선 교육원장 (제13대)</b></span>
            </p>
          </Col>
          <Col style={styles.colSidebar}
            xl={{span: 3, offset: 0}}
            lg={{span: 3, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <SidebarMenu />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default History
