import React, {
  useEffect,
  useState,
  useLayoutEffect
} from 'react'
import { 
  Card,
  Form,
  Button
} from "react-bootstrap";
import moment from 'moment'
import { themeColors } from '../Styles'
import Categories from "../infrastructure/Categories";
import { getPageContents } from '../infrastructure/ApisHandler'
import { signedIn } from '../infrastructure/TokenHelper'

const styles = {
  card: (size) => {
    return ({
      zIndex:999, 
      position: 'absolute', 
      right: (size[0]>992) ? '5%' : (size[0]>576) ? '50%' : '5%', 
      top: '100px', 
      width: (size[0]>=1200) 
              ? '30%'
              : (size[0]>992)
                ? '30%'
                : (size[0]>768)
                  ? '44%'
                  : (size[0]>576)
                    ? '44%'
                    : (size[0]>374)
                      ? '90%'
                      : '90%',
      opacity: '90%',
      border: '3px solid #767676'
    })
  },
  cardHeader: {
    display: 'flex', 
    justifyContent: 'space-between',
    fontSize: '0.9rem'
  },
  cardTitle: {
    marginBottom: 0,
    fontWeight: '600',
    fontSize: '1.2rem'
  },
  cardText: {
    fontSize: '1rem'
  },
  info: {
    marginTop: 0,
    marginBottom: '7px',
    paddingBottom: 0,
    fontSize: '12px',
    fontWeight: '300',
    color: themeColors.secondary,
  },
  button: {
    width: 130,
    backgroundColor: themeColors.button,
    color: '#FFF',
    borderWidth: 0,
    borderRadius: 20,
    marginRight: 20,
  },
}

const WebsiteNotice = ({onClose}) => {
  const [notice, setNotice] = useState([])
  let [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    function updateSize() {   
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    (async () => {

      const json = await getPageContents(Categories.websiteNotice)
      await setNotice(json.data || [])

    })()
  }, [])

  return (
    <Card
      bg='Light'
      className="mb-2"
      style={styles.card(size)}
    >
      <Card.Header style={styles.cardHeader}>
        {/* <span>{action}</span> */}
        <span><Form.Check label="창닫기" onClick={(e) => onClose(e.target.checked)} /></span>
        <span><i className="fas fa-times" onClick={() => onClose(true)} style={{color: '#767676'}}></i></span>
      </Card.Header>
      <Card.Body>
        <Card.Title style={styles.cardTitle}>{notice[0] && notice[0].title}</Card.Title>
        <Card.Text style={styles.info}>
          <small>Updated at: {moment(notice[0] && notice[0].dateTimestamp).format('YYYY-MM-DD')}</small>
        </Card.Text>
        <Card.Text className="ql-snow" style={styles.cardText}>
          {notice[0] &&
            <div dangerouslySetInnerHTML={
              {
                __html: notice[0].content
              }
            } className="ql-editor" style={{paddingLeft:0, lineHeight: 2.0}}>
            </div>
          }
        </Card.Text>
        {
          signedIn() && notice[0] && 
          <Button 
            variant="primary" 
            href={`/write-content/${notice[0].category.value}/${notice[0].contentId.value}`}
            style={styles.button}>
              공지글 수정
          </Button>
        }
      </Card.Body>
    </Card>
  )
}

export default WebsiteNotice
