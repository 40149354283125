import React, { useLayoutEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import PageTitleBanner from '../components/PageTitleBanner'
import SidebarMenu from '../components/SidebarMenu'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  row: {
    marginBottom: '25px'
  },
  colLeft: {
    paddingBottom: '7%',
    alignSelf: 'flex-start',
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    marginTop: '-14px',
    paddingBottom: '7%',
  },
  colImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '7% auto',
  },
  bannerImage: (size) => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width:(size[0]>=2000)?'500px':((size[0]<768)?'300px':'30vw'),
      textAlign: 'center'
    }
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  },
  introTitle: {
    marginBottom: '38px',
  },
  introSubtitle: {
    marginBottom: '23px',
    color: '#2B2B2B',
    fontWeight: 'bold'
  },
  blueText: {
    color: '#0071BC'
  },
  card: {
    height: '250px',
    marginBottom: '10px',
    borderWidth: 0,
    borderRadius: '7px 7px 7px 7px',
    boxShadow: '0 2px 10px 0 rgba(21, 87, 153, 0.1), 0 4px 15px 0 rgba(21, 87, 153, 0.05)'
  },
  cardSubtitle: {
    fontSize: '19px',
    color: '#2B2B2B',
    fontWeight: 'bold'
  }
  ,
  cardText: {
    fontSize: '14px',
    color: '#575757',
    fontWeight: 'normal'
  }
}


const Greetings = () => {
  document.title = "교육원장 인사말"

  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner
          imageUrl="/images/title-banner-bg-o.png"
          title="교육원장 인사말"
        />
      </Container>
      <Container style={styles.mainContainer} >
        <Row style={styles.row}>
          <Col style={styles.colLeft}
            xl={{span: 5, offset: 0}}
            lg={{span: 5, offset:0}}
            md={{span: 12, offset:0}}
            sm={{span: 12, offset:0}}
            xs={{span: 12, offset:0}}
          >
            <h5 style={{marginBottom:'0px'}}>주 독일 한국교육원 방문을</h5>
            <h1 className="primary-title" style={styles.introTitle}>진심으로 환영합니다.</h1>
            <p>안녕하십니까?</p>
            <p>우리 교육원은 ‘재외국민의 교육지원 등에 관한 법률’에 따라
              1981년 대한민국 정부가 설립한 기관으로, 
              독일에 거주하는 동포들의 한국어, 한국사 및 한국문화 교육 지원과
              독일내 한국어 교육 활성화, 유학생 지원 및 유학생 유치를  
              주요 업무로 하고 있습니다. 
            </p>
            <p>이를 위해 우리 교육원은 한국어 강좌를 개설‧운영하고,
              한글학교의 교육활동을 지원하며, 
              독일내 한국어능력시험을 운영하고 있습니다. 
              또한 독일 초중등학교를 대상으로 한국어 보급사업을 전개하고 있으며, 
              대한민국과 독일 교육기관간 교류, 유학생 교류를 통해 
              양국의 상호이해와 공동발전에 기여하고 있습니다.  
            </p>
            <p>동포 여러분들의 노력으로 독일에서 대한민국의 위상은 날이 갈수록 높아지고 
              있습니다. 우리 교육원은 동포여러분이 한국인으로서 정체성과
              자긍심을 가지고 생활할 수 있도록 적극 지원하겠습니다. 
              대한민국과 독일 양국의 협력과 소통의 공간이 될 수 있도록
              최선의 노력을 다하겠습니다. 
              여러분들의 애정어린 관심과 격려를 부탁드립니다.
            </p>
            <p>감사합니다.</p>
            <h5 style={{fontWeight: 'bold'}}>독일한국교육원장 <b>이지선</b></h5>
          </Col>
          <Col style={styles.colImage}
            xl={{span: 4, offset: 0}}
            lg={{span: 4, offset:0}}
            md={{span: 12, offset:0}}
            sm={{span: 12, offset:0}}
            xs={{span: 12, offset:0}}
          >
            <Image src="/images/img-about.png" style={styles.bannerImage(size)} />
          </Col>
          <Col style={styles.colSidebar}
            xl={{span: 3, offset:0}}
            lg={{span: 3, offset:0}}
            md={{span: 12, offset:0}}
            sm={{span: 12, offset:0}}
            xs={{span: 12, offset:0}}
          >
            <SidebarMenu />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Greetings
