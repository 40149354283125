import jwt_decode from 'jwt-decode'

const getRawToken = () => {
  return localStorage.getItem('keid_user_token')
}

const getJwt = () => {

  const token = getRawToken()
  if (token) {
    return jwt_decode(token)
  } else {
    return ''
  }
}

const signedIn = () => {
  const jwt = getJwt()
  if (jwt) {
    return Date.now() < jwt.exp * 1000
  } else {
    return false
  }

}

export { getRawToken, getJwt, signedIn }
