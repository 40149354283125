import React, { useEffect, useState, useMemo } from 'react'
import {
  Button,
  Container,
  Col,
  Form,
  Row,
  Spinner
} from 'react-bootstrap'
import { useHistory, useParams } from "react-router-dom"
import { useToasts } from 'react-toast-notifications'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ImageUploader from 'quill-image-uploader'
import { FilePond } from 'react-filepond'
import 'filepond/dist/filepond.min.css'

import { themeColors } from '../Styles'
import Categories from '../infrastructure/Categories'
import config from '../config.json'
import { getRawToken } from '../infrastructure/TokenHelper'
import {getContent, writeContent} from '../infrastructure/ApisHandler'

import PageTitleBanner from '../components/PageTitleBanner'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonRow: {
    marginBottom: '3rem',
    marginTop: '1rem'
  },
  body: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  },
  attachmentBox: {
    marginTop: '1rem',
    marginBottom: '2rem'
  },
  formTitle: {
    color: '#2A2A2A',
    fontSize: '41px',
    fontWeight: 'bold'
  },
  fileButton: {
    width: 130,
    backgroundColor: '#767676',
    borderWidth: 0,
    borderRadius: 20,
    float: 'center'
  },
  cancelButton: {
    marginLeft: 20,
    width: 80,
    backgroundColor: '#E0E1E2',
    borderWidth: 0,
    borderRadius: 20,
    color: '#767676',
  },
  submitButton: {
    width: 120,
    backgroundColor: themeColors.button,
    color: '#FFF',
    borderWidth: 0,
    borderRadius: 20
  },

}

Quill.register('modules/imageUploader', ImageUploader)

const WriteContent = () => {
  const history = useHistory()
  const params = useParams()

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [category, setCategory] = useState('')
  const [displayOrder, setDisplayOrder] = useState(0)
  const [files, setFiles] = useState([])
  const [attachments, setAttachments] = useState([])
  const [spinning, setSpinning] = useState(false)

  const { addToast } = useToasts()

  let pond

  useEffect(() => {
    if (!params.id)
      return

    (async () => {
      setSpinning(true)
      const json = await getContent(params.category, params.id)
      setTitle(json.data.title)
      setCategory(json.data.category)
      setContent(json.data.content)
      setDisplayOrder(json.data.displayOrder)
      setSpinning(false)
    })()
  }, [params.category, params.id])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setSpinning(true)

    const json = await writeContent(params.id,
      title,
      content,
      category,
      displayOrder)
      if(!json.result) {
        setSpinning(false)  
        addToast(json.message, {
          appearance: 'error',
          autoDismiss: true
        })

      history.push(`/${category}`)
      return
    }

    setSpinning(false)
    addToast(`"${title}" 작성완료 되었습니다.`, {
      appearance: 'success',
      autoDismiss: true
    })

    history.push(`/${category}`)
  }

  const handleCancle = async (event) => {
    event.preventDefault()
    
    addToast(`"작성을 취소하였습니다.`, {
      appearance: 'success',
      autoDismiss: true
    })

    history.push(`/${category}`)
  }

  const uploadFile = async (file) => {
    const initialResponse = await fetch(`${config.API_HOST}/attachments`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'appliation/json',
        'Authorization': `Bearer ${getRawToken()}`
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        filename: file.name
      })
    })

    const json = await initialResponse.json()
    const attachment = json.data

    await fetch(attachment.signedUrl, {
      method: 'PUT',
      mode: 'cors',
      body: file
    })

    return attachment
  }

  const process = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
    ) => {

    const attachment = await uploadFile(file)
    load('success')
    setAttachments(x => [...x, attachment])
  }

  const modules = useMemo(() => ({
    toolbar: {
      container:[
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean']
    ]},
    imageUploader: {
      upload : async (file) => {
        const fileResponse = await uploadFile(file)
        return fileResponse.objectUrl
      }
    }
  }), [])

  const handleCategories = (e) => {
    console.log(e)

    if(e.target.selectedIndex > 0) {
      setCategory(e.target.value)
    }
  }

  return (
    <>
      <Container fluid style={styles.headerContainer}>
      <PageTitleBanner
          imageUrl="/images/title-banner-bg-o.png"
          title="페이지 작성"
        />
      </Container>
      <Container style={styles.mainContainer} >
        <Row style={styles.body}>
          <Col md={12}>
            <Form>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="formTitle">
                    <Form.Label style={styles.formTitle}>
                      {
                        (params.id) ? '페이지 수정' : '새 페이지 작성'
                      }
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="제목"
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value)
                      }}
                      />
                  </Form.Group>

                  <Form.Group controlId="formSection" as={Row}>
                    <Col md={6}>
                      <Form.Control 
                        as="select"
                        custom
                        value={category}
                        onChange={handleCategories}>
                        <option>Please select</option>
                        <option value={Categories.keidSchoolInfo}>한글학교 소개</option>
                        <option value={Categories.studyInKoreaInfo}>한국 유학정보</option>
                        <option value={Categories.studiumInKorea}>Studium in Korea</option>
                        <option value={Categories.websiteNotice}>홈페이지 공지 (메인화면)</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  {category === Categories.keidSchoolInfo && 
                    // <Col md={6}>
                      <Form.Group as={Row}>
                        <Col md={2}>
                          <Form.Label>글 순서</Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="number"
                            placeholder="순서"
                            value={displayOrder}
                            onChange={(event) => {
                              setDisplayOrder(Number(event.target.value))
                            }}
                            />
                        </Col>
                        </Form.Group>
                    // </Col>
                    }

                  <Form.Group controlId="formContent">
                    <Form.Label>글 본문</Form.Label>

                    <ReactQuill
                      theme="snow"
                      value={content||''}
                      onChange={setContent}
                      modules={modules}
                      formats={[
                        'header',
                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                        'color', 'background',
                        'list', 'bullet', 'indent',
                        'align',
                        'link', 'image',
                        'clean'
                      ]}
                      style={{height:'300px', marginBottom: '100px'}}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row style={styles.attachmentBox}>
                <Col>
                  <ListAttachments title="첨부파일" items={attachments}/>
                </Col>
              </Row> */}

              <Row>
                <Col md={12}>
                  <FilePond
                    ref={ref => pond = ref }
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={10}
                    name="files"
                    labelIdle='Drag / Drop your files or <span class="filepond--label-action">Browse</span>'
                    server={{
                      process: process
                    }}

                  />
                </Col>
              </Row>
              <Row style={styles.buttonRow}>
                <Col md={12}>
                  <Button 
                    className="float-right" 
                    variant="primary" 
                    type="cancel" 
                    style={styles.cancelButton}
                    onClick={handleCancle}
                  >
                    취소
                  </Button>
                  <Button
                    className="float-right"
                    variant="primary"
                    type="submit"
                    style={styles.submitButton}
                    onClick={handleSubmit}
                    >
                      {
                        (spinning) &&
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{marginRight:'10px'}}
                          />
                      } 완료
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default WriteContent
