import React, { useEffect, useState, useMemo } from "react";

import { Button, Container, Col, Form, Row, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageUploader from "quill-image-uploader";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

import { themeColors } from "../Styles";
import Categories from "../infrastructure/Categories";
import { signedIn } from '../infrastructure/TokenHelper';
import {
  getPost,
  writePost,
  uploadAttachment,
  createThumbnailImage,
} from "../infrastructure/ApisHandler";

import PageTitleBanner from "../components/PageTitleBanner";
import DialogBox from '../components/DialogBox'

const styles = {
  headerContainer: {
    padding: 0,
  },
  mainContainer: {
    padding: "2rem 2% 1rem 2%",
  },
  imageOverlay: {
    display: "flex",
    alignItems: "center",
  },
  buttonRow: {
    marginBottom: "3rem",
    marginTop: "1rem",
  },
  body: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    width: "100%",
    textAlign: "center",
    color: "#FFF",
    fontSize: "42px",
    fontWeight: "bold",
  },
  attachmentBox: {
    marginTop: "1rem",
    marginBottom: "2rem",
  },
  formTitle: {
    color: "#2A2A2A",
    fontSize: "41px",
    fontWeight: "bold",
  },
  fileButton: {
    width: 130,
    backgroundColor: "#767676",
    borderWidth: 0,
    borderRadius: 20,
    float: "center",
  },
  cancelButton: {
    marginLeft: 20,
    width: 80,
    backgroundColor: "#E0E1E2",
    borderWidth: 0,
    borderRadius: 20,
    color: "#767676",
  },
  submitButton: {
    width: 120,
    backgroundColor: themeColors.button,
    color: "#FFF",
    borderWidth: 0,
    borderRadius: 20,
  },
  attachmentHeader: {
    margin: '1rem 0px',
    padding: '5px 0px',
    fontSize: '14px',
    fontWeight: 'bold',
    borderBottom: `solid 4px ${themeColors.primary}`,
  },
  attachmentIcon: {
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 'normal',
    color: themeColors.primary,
    textShadow: '1px 1px 3px rgba(255, 160, 72, 0.6)',
    cursor: 'pointer'
  },
  attachmentList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  attachmentInfo: {
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: '400',
    color: themeColors.primary,
  },
};

Quill.register("modules/imageUploader", ImageUploader);

const WritePost = () => {
  const history = useHistory();
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [categories, setCategories] = useState([]);
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [spinning, setSpinning] = useState(false);
  const { addToast } = useToasts();
  const [show, setShow] = useState(false)
  const [dialogContent, setDialogContent] = useState('')
  const [dialogAction, setDialogAction] = useState(() => {})

  // eslint-disable-next-line no-unused-vars
  let pond;

  useEffect(() => {
    if (!id) return;

    (async () => {
      const json = await getPost(id);
      setTitle(json.data.title);
      setCategories(json.data.categories);
      setContent(json.data.content);
      setThumbnailUrl(json.data.thumbnailUrl);
      setAttachments(json.data.attachments.map((x, i) => x));
    })();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSpinning(true);

    const json = await writePost(
      id,
      title,
      content,
      categories,
      attachments,
      thumbnailUrl
    );
    if (!json) {
      addToast(json.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }

    setSpinning(false);
    addToast(`"${title}" 작성완료 되었습니다.`, {
      appearance: "success",
      autoDismiss: true,
    });

    history.push(`/${categories[0]}`);
  };

  const handleCancle = async (event) => {
    event.preventDefault()
    
    addToast(`"작성을 취소하였습니다.`, {
      appearance: 'success',
      autoDismiss: true
    })

    history.push(`/${categories[0]}`);
  }

  const process = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
  ) => {
    const uploadResult = await uploadAttachment(file);
    if (!uploadResult.ok) {
      addToast(uploadResult.message, {
        appearance: "error",
        autoDismiss: false,
      })
      error(uploadResult.message)
      return
    }

    load("success")
    setAttachments((x) => [...x, uploadResult.attachment])
  }

  const imageUpload = async (file) => {
    setSpinning(true);
    const fileResponse = await uploadAttachment(file);

    const thumbnailResponse = await createThumbnailImage(fileResponse.attachment.key);
    setThumbnailUrl(thumbnailResponse.data.thumbnailUrl);

    setSpinning(false);
    return fileResponse.attachment.objectUrl;
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ color: [] }, { background: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ align: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
      imageUploader: {
        upload: imageUpload,
      },
    }),
    []
  );

  const handleCategories = (e) => {
    console.log(e);

    if (e.target.checked) {
      setCategories([...categories, e.target.id]);
    } else {
      setCategories(categories.filter((x) => x !== e.target.id));
    }
  };
  
  const handleDialogAction = async () => {
    console.log('handleDialogaction fired...')
    setShow(false)
    await dialogAction()
  };

  return (
    <>
      {spinning && (
        <Spinner
          variant="warning"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          style={{
            zIndex: 999,
            position: "absolute",
            top: "50%",
            left: "50%",
            marginRight: "10px",
          }}
        />
      )}
      <DialogBox
        show={show}
        handleClose={() => setShow(false)}
        handleDialogAction={handleDialogAction}
        title="확인"
        content={dialogContent}
        actionLabel="삭제"
      />
      
      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner
          imageUrl="/images/title-banner-bg-o.png"
          title="새 글쓰기"
        />
      </Container>
      <Container style={styles.mainContainer}>
        <Row style={styles.body}>
          <Col md={12}>
            <Form>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="formTitle">
                    <Form.Label style={styles.formTitle}>새글쓰기</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="제목"
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group controlId="formSection" as={Row}>
                    <Col md={3}>
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(Categories.keidAnnouncement) > -1
                        }
                        id={Categories.keidAnnouncement}
                        label="교육원 공지사항"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={categories.indexOf(Categories.keidNews) > -1}
                        id={Categories.keidNews}
                        label="교육원 새소식"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(
                            Categories.koreanSchoolAnnouncement
                          ) > -1
                        }
                        id={Categories.koreanSchoolAnnouncement}
                        label="한글학교 공지사항"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(Categories.koreanSchoolNews) > -1
                        }
                        id={Categories.koreanSchoolNews}
                        label="한글학교 새소식"
                        onChange={handleCategories}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(
                            Categories.koreanLanguageAdoption
                          ) > -1
                        }
                        id={Categories.koreanLanguageAdoption}
                        label="독일 초중등학교 한국어 채택"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(Categories.koreanClass) > -1
                        }
                        id={Categories.koreanClass}
                        label="한국어 강좌"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={categories.indexOf(Categories.koreanTest) > -1}
                        id={Categories.koreanTest}
                        label="한국어 능력시험"
                        onChange={handleCategories}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(Categories.germanEducationSystem) >
                          -1
                        }
                        id={Categories.germanEducationSystem}
                        label="독일 교육제도"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(Categories.germanEducationInfo) >
                          -1
                        }
                        id={Categories.germanEducationInfo}
                        label="독일교육 소식"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(
                            Categories.studyInKoreaAnnouncement
                          ) > -1
                        }
                        id={Categories.studyInKoreaAnnouncement}
                        label="한국유학 공지"
                        onChange={handleCategories}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(Categories.keidAnnouncementDE) > -1
                        }
                        id={Categories.keidAnnouncementDE}
                        label="Aktuelles"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(Categories.koreanischAG) > -1
                        }
                        id={Categories.koreanischAG}
                        label="Koreanisch AG"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(Categories.koreanischKurse) > -1
                        }
                        id={Categories.koreanischKurse}
                        label="koreanisch Kurse"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={
                          categories.indexOf(Categories.studiumInKorea) > -1
                        }
                        id={Categories.studiumInKorea}
                        label="Studium In Korea"
                        onChange={handleCategories}
                      />
                      <Form.Check
                        type="checkbox"
                        checked={categories.indexOf(Categories.topikDE) > -1}
                        id={Categories.topikDE}
                        label="TOPIK"
                        onChange={handleCategories}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="formContent">
                    <Form.Label>글 본문</Form.Label>

                    <ReactQuill
                      theme="snow"
                      value={content || ""}
                      onChange={setContent}
                      modules={modules}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "color",
                        "background",
                        "list",
                        "bullet",
                        "indent",
                        "align",
                        "link",
                        "image",
                        "video",
                        "clean",
                      ]}
                      style={{ height: "100%", marginBottom: "100px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={styles.attachmentBox}>
                <Col>
                  <div className="primary-title" style={styles.attachmentHeader}>첨부파일</div>
                  {
                    (attachments) &&
                      attachments.map((x,i) => (
                        <Row key={i} style={styles.attachmentList}>
                          <Col md={11}>
                            <a href={x.objectUrl} download={x.name} target="_blank" rel="noreferrer" style={styles.attachmentInfo}>
                              <i className="fas fa-paperclip"></i> {x.name}
                            </a>
                          </Col>
                          <Col md={1}>
                            {
                              signedIn() &&
                              <i className="far fa-times-circle"
                                style={styles.attachmentIcon}
                                onClick={() => {
                                    setDialogAction(() => () => setAttachments(attachments.filter(item => item.key !== x.key)))
                                    setDialogContent(`"${x.name}" 삭제하시겠습니까?`)
                                    setShow(true)
                                  }
                                }>
                              </i>
                            }
                          </Col>
                        </Row>
                      ))
                  }
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FilePond
                    ref={(ref) => (pond = ref)}
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={10}
                    name="files"
                    labelIdle='Drag / Drop your files or <span class="filepond--label-action">Browse</span>'
                    server={{
                      process: process,
                    }}
                  />
                </Col>
              </Row>
              <Row style={styles.buttonRow}>
                <Col md={12}>
                  <Button
                    className="float-right"
                    variant="primary"
                    type="cancel"
                    style={styles.cancelButton}
                    onClick={handleCancle}
                  >
                    취소
                  </Button>
                  <Button
                    className="float-right"
                    variant="primary"
                    type="submit"
                    style={styles.submitButton}
                    onClick={handleSubmit}
                  >
                    완료
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WritePost;
