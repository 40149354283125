import React, { useLayoutEffect, useState } from 'react'
import { 
  Card,
  Form,
  Image
} from "react-bootstrap";
import { themeColors } from '../Styles'

const styles = {
  card: (size) => {
    return ({
      zIndex:1000, 
      position: 'absolute', 
      right: (size[0]>=1200) 
              ? '36%'
              : (size[0]>992)
                ? '36%'
                : (size[0]>768)
                  ? '5%'
                  : (size[0]>576)
                    ? '5%'
                    : '5%',
      top: (size[0]>576) ? '100px' : 'auto',
      marginTop: (size[0]>576) ? '0%' : '50%', 
      width: (size[0]>=1200) 
              ? '30%'
              : (size[0]>992)
                ? '30%'
                : (size[0]>768)
                  ? '44%'
                  : (size[0]>576)
                    ? '44%'
                    : '90%',
      opacity: '92%',
      border: '3px solid #f37335'
    })
  },
  cardHeader: {
    display: 'flex', 
    justifyContent: 'space-between',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    backgroundColor: '#f37335',
    color: '#fff'
  },
  caradTitle: {
    fontWeight: '800',
    fontSize: '1.2rem'
  },
  cardText: {
    fontSize: '1rem'
  },
  button: {
    width: 130,
    backgroundColor: themeColors.primary,
    color: '#FFF',
    borderWidth: 0,
    borderRadius: 20,
    marginRight: 20,
  },
}

const PopupCard = ({action, title, contents, url, onClose}) => {
  let [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    function updateSize() {   
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Card
      bg='Light'
      className="mb-2"
      style={styles.card(size)}
    >
      <Card.Header style={styles.cardHeader}>
        {/* <span>{action}</span> */}
        <span><Form.Check label="창닫기" onClick={(e) => onClose(e.target.checked)} /></span>
        <span><i className="fas fa-times" onClick={() => onClose(true)} style={{color: '#fff'}}></i></span>
      </Card.Header>
      <Card.Body>
        <Card.Title style={styles.cardTitle}>{title}</Card.Title>
        <a href='https://www.studyinkorea.go.kr/ko/expo/preRgstrExpo.do?expo_no=124&img_se=NATI' target='blank'><Image src="/images/banner-study-korea.jpg" thumbnail /></a>
        <Card.Text style={styles.cardText}>
          {contents}
        </Card.Text>
        {/* <Button variant="primary" href={url} style={styles.button}>자세히보기</Button> */}
      </Card.Body>
    </Card>
  )
}

export default PopupCard
