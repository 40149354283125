import React from 'react'
import {
  Accordion,
  Card,
  ListGroup
} from 'react-bootstrap'
import { themeColors } from '../Styles' 
import Categories from '../infrastructure/Categories'

const styles = {
  accordion: {
    width: '90%',
    border: 0
  },
  card: {
    border: 0
  },
  sidebarTitle: {
    paddingLeft: 0,
    backgroundColor: themeColors.background,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 'solid 2px',
    color: themeColors.primary,
    fontWeight:'normal',
    fontSize: '20px',
    textDecoration: 'none'
  },
  sidebarMenu: {
    backgroundColor: themeColors.background,
    paddingLeft: 10,
    borderWidth:0,
    color: themeColors.secondary,
    fontsize: '16px',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    border: 0
  }
}

const SidebarMenu = () => {
  return (
    <>
      <Accordion defaultActiveKey="0" style={styles.accordion}>
        <Card style={styles.card}>
          <Accordion.Toggle as={Card.Header} eventKey="0" style={styles.sidebarTitle}>
            <a href="#" >교육원소개</a>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body style={styles.sidebarMenu}>
              <ListGroup style={{}}>
                <ListGroup.Item action href={`/${Categories.keidAnnouncement}`} style={styles.sidebarMenu}>
                  교육원 공지사항
                </ListGroup.Item>
                <ListGroup.Item action href={`/${Categories.keidNews}`} style={styles.sidebarMenu}>
                  교육원 새소식
                </ListGroup.Item>
                <ListGroup.Item action href="/greetings" style={styles.sidebarMenu}>
                  교육원장 인사말
                </ListGroup.Item>
                <ListGroup.Item action href="/services" style={styles.sidebarMenu}>
                  교육원 주요 업무
                </ListGroup.Item>
                <ListGroup.Item action href="/history" style={styles.sidebarMenu}>
                  교육원 연혁
                </ListGroup.Item>
                <ListGroup.Item action href="/contact" style={styles.sidebarMenu}>
                  오시는 길
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card style={styles.card}>
          <Accordion.Toggle href="#" as={Card.Header} eventKey="1" style={styles.sidebarTitle}>
            <a href="#" >한글학교</a>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body style={styles.sidebarMenu}>
              <ListGroup style={{}}>
                <ListGroup.Item action href={`/keid-school-info`} style={styles.sidebarMenu}>
                  한글학교 소개
                </ListGroup.Item>
                <ListGroup.Item action href={`/${Categories.koreanSchoolAnnouncement}`} style={styles.sidebarMenu}>
                  한글학교 공지사항
                </ListGroup.Item>
                <ListGroup.Item action href={`/${Categories.koreanSchoolNews}`} style={styles.sidebarMenu}>
                  한글학교 새소식
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card style={styles.card}>
          <Accordion.Toggle as={Card.Header} eventKey="2" style={styles.sidebarTitle}>
            <a href="#" >한국어보급</a>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body style={styles.sidebarMenu}>
              <ListGroup style={{}}>
                <ListGroup.Item action href={`/${Categories.koreanLanguageAdoption}`} style={styles.sidebarMenu}>
                  독일 초중등학교 한국어 채택
                </ListGroup.Item>
                <ListGroup.Item action href={`/${Categories.koreanClass}`} style={styles.sidebarMenu}>
                  한국어강좌
                </ListGroup.Item>
                <ListGroup.Item action href={`/${Categories.koreanTest}`} style={styles.sidebarMenu}>
                  한국어능력시험 (TOPIK)
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card style={styles.card}>
          <Accordion.Toggle as={Card.Header} eventKey="3" style={styles.sidebarTitle}>
            <a href="#" >독일교육</a>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body style={styles.sidebarMenu}>
              <ListGroup style={{}}>
                <ListGroup.Item action href={`/${Categories.germanEducationSystem}`} style={styles.sidebarMenu}>
                  독일 교육제도
                </ListGroup.Item>
                <ListGroup.Item action href={`/${Categories.germanEducationInfo}`} style={styles.sidebarMenu}>
                  독일교육 소식
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card style={styles.card}>
          <Accordion.Toggle as={Card.Header} eventKey="4" style={styles.sidebarTitle}>
            <a href="#" >한국유학</a>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body style={styles.sidebarMenu}>
              <ListGroup style={{}}>
                <ListGroup.Item action href={`/${Categories.studyInKoreaInfo}`} style={styles.sidebarMenu}>
                  한국유학 정보(GKS) - Study in Korea
                </ListGroup.Item>
                <ListGroup.Item action href={`/${Categories.studyInKoreaAnnouncement}`} style={styles.sidebarMenu}>
                  한국유학 공지
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default SidebarMenu
