import React from 'react'
import {
  Container,
  Row,
  Col,
  Image
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import PageTitleBanner from '../components/PageTitleBanner'
import SidebarMenu from '../components/SidebarMenu'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  row: {
    marginBottom: '25px'
  },
  colLeft: {
    marginTop: '25px',
    paddingBottom: '7%',
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingBottom: '7%',

  },
  colCenter: {
    marginTop: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '7%',
  },
  imageColumnRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  },
  sectionTag: {
    marginBottom: '0px',
    color: themeColors.primary,
    textAlign: 'center'
  },
  sectionTitle: {
    marginBottom: '50px',
    color: '#2B2B2B',
    fontFamily: 'Gosanja',
    fontWeight: '300', 
    fontSize: '32px',
    textAlign: 'center'
  },
  sectionSubtitle: {
    color: '#575757',
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'left',
  },
  sectionDescription: {
    color: '#575757',
    fontSize: '14px',
    textAlign: 'left',
  },
  historyDetail: {
    color: themeColors.primary,
    fontSize: '14px'
  },
  button: {
    width:'192px',
    borderRadius:50,
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  card: {
    height: '250px',
    marginBottom: '10px',
    borderWidth: 0,
    borderRadius: '7px 7px 7px 7px',
    boxShadow: '0 2px 10px 0 rgba(21, 87, 153, 0.1), 0 4px 15px 0 rgba(21, 87, 153, 0.05)'
  },
  cardSubtitle: {
    fontSize: '19px',
    color: '#2B2B2B',
    fontWeight: 'bold'
  }
  ,
  cardText: {
    fontSize: '14px',
    color: '#575757',
    fontWeight: 'normal'
  }
}


const Contact = () => {
  document.title = "오시는 길"

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner
          imageUrl="/images/title-banner-bg-o.png"
          title="오시는 길"
        />
      </Container>
      <Container style={styles.mainContainer} id="services">
        <Row style={styles.row}>
          <Col>
          {/* <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4963.086138336348!2d8.620484404210933!3d50.08315986344074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0ba4ba5eac8b%3A0xb16e24ae80150ed8!2sLyoner%20Str.%2034%2C%2060528%20Frankfurt%20am%20Main%2C%20Germany!5e0!3m2!1sen!2suk!4v1612478301243!5m2!1sen!2suk" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
          <Image src="/images/keid-map.jpg" style={{width: '100%'}} />
          </Col>
        </Row>

        <Row style={styles.row}>
          <Col style={styles.colLeft}
            xl={{span: 3, offset: 0}}
            lg={{span: 10, offset: 1}}
            md={{span: 10, offset: 1}}
            sm={{span: 10, offset: 1}}
            xs={{span: 10, offset: 1}}
          >
            {/* <p style={styles.sectionTag}>주독일한국교육원</p> */}
            <h1 style={styles.sectionTitle}>연락처 및 주소</h1>
            <h5 style={styles.sectionSubtitle}>연락처</h5>
            <p style={styles.sectionDescription}>
              Tel ㅣ 069-95675231 <br />
              Fax ㅣ 069-56003987 <br />
              email ㅣ keid@mofa.or.kr
            </p>
            <h5 style={styles.sectionSubtitle}>주소</h5>
            <p style={styles.sectionDescription}>
              OLIVETTI (Turm 2/13. OG) <br />
              Lyoner Str. 34, 60528 Frankfurt am Main
            </p>
            <h5 style={styles.sectionSubtitle}>사무실</h5>
            <p style={styles.sectionDescription}>
              11층 ㅣ 총영사관 민원실 (영사업무, 비자업무 등)<br />
              12층 ㅣ 총영사관 리셉션 및 직원 사무실 <br />
              13층 ㅣ 주독일한국교육원 사무실 및 총영사관 직원 사무실 <br />
            </p>
            <h5 style={styles.sectionSubtitle}>주차장</h5>
            <p style={styles.sectionDescription}>
              건물 내 주차장 이용 가능<br />
            </p>
          </Col>
          <Col style={styles.colCenter}
            xl={{span: 4, offset: 1}}
            lg={{span: 10, offset: 1}}
            md={{span: 10, offset: 1}}
            sm={{span: 10, offset: 1}}
            xs={{span: 10, offset: 1}}
          >
            {/* <p style={styles.sectionTag}>교통편 안내</p> */}
            <h1 style={styles.sectionTitle}>찾아오시는 길</h1>
            <h5 style={styles.sectionSubtitle}>S-Bahn</h5>
            <p style={styles.sectionDescription}>
              Frankfurt 중앙역에서 S8, S9 번 &#8594; Niederrad Bahnhof 하차 &#8594; Strassenbahn 12 번 &#8594; Buerostadt Niederrad 하차 &#8594; 진행방향으로 도보 5분 정도 소요
            </p>
            <h5 style={styles.sectionSubtitle}>Strassenbahn</h5>
            <p style={styles.sectionDescription}>
              Strassenbahn Strassenbahn 16번, 21번(중앙역 방향) &#8594; Baseler Str. 하차 &#8594; Strassenbahn 12번으로 갈아타고 &#8594; Buerostadt Niederrad 하차
            </p>
            <h5 style={styles.sectionSubtitle}>S-Bahn</h5>
            <p style={styles.sectionDescription}>
              Bus 이용 Niederrad (Bahnhof) 에서 79번 &#8594; Buerostadt Niederrad 하차
            </p>
            <h5 style={styles.sectionSubtitle}>S-Bahn</h5>
            <p style={styles.sectionDescription}>
              고속도로 이용 Kassel A5 방향에서 오는 경우 Frankfurter Kreuz 에서 A5번을 타고(Kassel 방향) Niederrad 출구로 나와 Schwanheimer Ufer 간선도로를 따라 오다가 첫번째에서 우회전 (Lyoner Str.)해서 직진 (약 3분정도)하다 보면 오른쪽에 쌍둥이 건물(Olivetti Turm)에 영사관 소재
            </p>
          </Col>
          <Col style={styles.colSidebar}
            xl={{span: 3, offset: 0}}
            lg={{span: 10, offset: 1}}
            md={{span: 10, offset: 1}}
            sm={{span: 10, offset: 1}}
            xs={{span: 10, offset: 1}}
          >
            <SidebarMenu />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact
