import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  Col,
  Card,
  ListGroup,
  Button,
  Spinner
} from 'react-bootstrap'
import {
  Link
} from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { themeColors } from '../Styles'
import Categories from '../infrastructure/Categories'
import { getCategoryPosts, updateFavouriteCategoryPosts } from '../infrastructure/ApisHandler'
import { signedIn } from '../infrastructure/TokenHelper'

const styles = {
  Col: {
    marginBottom: '1rem',
  },
  title: {
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  card: {
    height: '360px',
    borderWidth: 0,
    borderRadius: '25px 0px 25px 0px',
    boxShadow: '5px 9px 20px rgba(118, 118, 118, 0.45)',
    marginBottom: 0,
    paddingBottom: 0,
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    padding: '0rem 1.25rem',
    textAlign: 'left',
    fontWeight: 'bold',
    fontColor: '#2B2B2B',
    fontSize: '20px'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    borderWidth:0,
    padding: '0.25rem 1.25rem',
  },
  content: {
    fontSize: '14px',
    fontWeight: '300',
    color: '#2B2B2B',
  },
  info: {
    marginBottom: -3,
    paddingBottom: 0,
    fontSize: '11px',
    fontWeight: '300',
    color: '#155799',
  },
  icon: {
    marginLeft: '10px',
    color: themeColors.primary,
    fontSize: '11px',
  },
  noticeText: {
    border: `solid 2px ${themeColors.primary}`, 
    borderRadius: '5px', 
    color: themeColors.secondary, 
    fontWeight: 'bold', 
    fontSize: '12px', 
    padding: '2px', 
    marginRight: '7px'
  },
  button: {
    borderRadius:50,
    width: '38px',
    fontSize: '15px',
    fontWeight: 'normal',
    color: '#FFFFFF',
  }
}

const LatestAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([])
  let [size, setSize] = useState([0, 0])
  const [spinning, setSpinning] = useState(false)
  const { addToast } = useToasts()

  useLayoutEffect(() => {
    function updateSize() {   
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    (async () => {

      const json = await getCategoryPosts(Categories.keidAnnouncement)
      setAnnouncements(json.data || [])

    })()
  }, [])

  const actionFavourite = async (category, id, value) => {
    if (!signedIn()) {
      addToast("새로 로그인해주시기 바랍니다.", {
        appearance: 'error',
        autoDismiss: true
      })

      return
    }

    setSpinning(true)

    await updateFavouriteCategoryPosts(category, id, value)
    const json = await getCategoryPosts(Categories.keidAnnouncement)
    setAnnouncements(json.data || [])

    setSpinning(false)
  }

  return (
    <>
      {
        (spinning) &&
          <Spinner
            variant="warning"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            style={{zIndex: 999, position: 'absolute', top: '50%', left: '50%', marginRight:'10px'}}
          />
      }
      <Col xl={6} lg={6} md={12} style={styles.Col}>
        <Card style={styles.card}>
          <Card.Body style={{textAlign: 'left'}}>
            <Card.Title style={styles.cardHeader}>
              <span>공지사항</span>
              <span>
                <Button variant="button" style={styles.button} href={`/${Categories.keidAnnouncement}`} >
                  <i className="fas fa-plus" ></i>
                </Button>
              </span>
            </Card.Title>
            <hr />
            <ListGroup>
              {announcements.map((x,i) => (
                (i<8) &&
                <ListGroup.Item style={styles.listItem} key={i}>
                  <Link to={`/${Categories.keidAnnouncement}/${x.postId}`} >
                    <span style={styles.content}>
                      {x.favourite ? <span style={styles.noticeText}>공지</span> : ''}
                      {`${x.title.substring(0,
                        (size[0]>=1200) 
                        ? 35
                        : (size[0]>992)
                          ? 24
                          : (size[0]>768)
                            ? 45
                            : (size[0]>576)
                              ? 30
                              : (size[0]>374)
                                ? 15
                                : 7
                        )}`}
                    </span>
                  </Link>
                  <span style={styles.info}>
                    {x.dateTimestamp.split('T')[0]}
                    {
                      signedIn() && (x.favourite 
                        ? <i className="fas fa-star" style={styles.icon} onClick={() => actionFavourite(x.category, x.postId, false)}></i>
                        : <i className="far fa-star" style={styles.icon} onClick={() => actionFavourite(x.category, x.postId, true)}></i>
                      )
                    }
                  </span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}

export default LatestAnnouncements
