import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Spinner
} from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'
import { themeColors } from '../Styles'
import { signedIn } from '../infrastructure/TokenHelper'
import { getPost, getViewCount, deletePost, deletePostAttachment } from '../infrastructure/ApisHandler'

import Categories from '../infrastructure/Categories'
import ListAttachments from '../components/ListAttachments'
import PageTitleBanner from '../components/PageTitleBanner'
import DialogBox from '../components/DialogBox'
import SidebarMenuDE from '../components/SidebarMenuDE'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%',
  },
  mainContainerWithBG: {
    padding: '2rem 2% 1rem 2%',
    backgroundColor: "#FAFAFB"
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    marginTop: '-14px',
    paddingBottom: '7%',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 2rem 1rem 2rem',
  },
  contentBox: {
    padding: '1rem 2rem 2rem 2rem',
    borderWidth: 0,
    borderRadius: '7px 7px 7px 7px',
  },
  attachmentBox: {
    margin: 'auto 2rem',
    padding: 0,
    backgroundColor: '#FAFAFB',
  },
  buttonBox: {
    margin: '2rem 2rem',
    marginBottom: '4rem',
    backgroundColor: '#FAFAFB',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '3px',
    padding: '5px 0px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: themeColors.primary,
    borderBottom: `solid 4px ${themeColors.primary}`,
  },
  subject: {
    paddingTop: '1rem',
    fontSize: '26px',
    fontWeight: '800',
    borderTop: `solid 0px ${themeColors.primary}`,
  },
  content: {
    fontSize: '21px',
    fontWeight: 'normal',
    color: '#575757',
    objectFit: 'contain',
    OverflowEvent: 'hidden',
    width: '100%'
  },
  attachment: {
    padding: '5px',
    fontSize: '14px',
    color: '#767676',
  },
  buttonLeft: {
    width: 120,
    borderWidth: 0,
    borderRadius: '30px',
    backgroundColor: '#E0E1E2',
    color: '#767676',
    fontSize: '18px',
  },
  buttonRight: {
    right:0,
    width: 120,
    borderWidth: 0,
    borderRadius: '30px',
    backgroundColor: themeColors.button,
    color: '#FFF',
    fontSize: '18px',
  },
  cancelButton: {
    marginLeft: 20,
    width: 100,
    backgroundColor: '#E0E1E2',
    borderWidth: 0,
    borderRadius: 20,
    color: '#767676',
    fontSize: '18px',
  },
  image: {
    paddingLeft: 0,
    paddingBottom: '2rem',
    borderWidth: 0
  }
}

const ViewPostDE = ({category}) => {
  const history = useHistory()
  const { id } = useParams()
  const [userSignedIn, setUserSignedIn] = useState(false)
  const [spinning, setSpinning] = useState(false)
  const { addToast } = useToasts()
  const [show, setShow] = useState(false)
  const [post, setPost] = useState({})
  const [viewCount, setViewCount] = useState(0)

  const titles = {}
  titles[Categories.keidAnnouncementDE] =  { title:'Aktuelles', background:'/images/title-banner-bg-o.png' }
  titles[Categories.koreanischAG] =  { title:'Koreanisch AG', background:'/images/title-banner-bg-o.png' }
  titles[Categories.koreanischKurse] =  { title:'Koreanisch Kurse', background:'/images/title-banner-bg-o.png' }
  titles[Categories.topikDE] =  { title:'TOPIK', background:'/images/title-banner-bg-o.png' }
  
  useEffect(() => {
    (async () => {
      setSpinning(true)

      const jsonPost = await getPost(id)
      setPost(jsonPost.data)
      document.title = jsonPost.data.title

      const jsonViewCount = await getViewCount(id)
      setViewCount(jsonViewCount.data.count)
      setUserSignedIn(signedIn())
      setSpinning(false)
    })()
  }, [id])

  const handleDelete = async () => {
    setSpinning(true)

    const json = await deletePost(id)

    if(!json) {
      console.log(json)
      addToast(json.message, {
        appearance: 'error',
        autoDismiss: true
      })
    }
    setSpinning(false)
    addToast(`"${post.title}" deleted`, {
      appearance: 'success',
      autoDismiss: true
    })
    history.push(`/${category}`)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleAction = async () => {
    setShow(false)
    await handleDelete()
  }

  const handleAttachmentDelete = async (key) => {
    setSpinning(true)

    const json = await deletePostAttachment(id, key)

    if(!json) {
      console.log(json)
      addToast(json.message, {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast(`"${key}" of attachments is deleted`, {
      appearance: 'success',
      autoDismiss: true
    })

    const jsonPost = await getPost(id)
    setPost(jsonPost.data)

    setSpinning(false)
  }

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      {
        (spinning) &&
          <Spinner
            variant="warning"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            style={{zIndex: 999, position: 'absolute', top: '50%', left: '50%', marginRight:'10px'}}
          />
      }
      <DialogBox
        show={show}
        handleClose={handleClose}
        handleAction={handleAction}
        title="확인"
        content={`"${post.title}" 삭제하시겠습니까?`}
        actionLabel="삭제"
      />

      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner
          imageUrl={titles[category]?titles[category].background:'/images/title-banner-bg-o.png'}
          title={titles[category]?titles[category].title:'Koreanisches Erziehungsinstitut'}
        />
      </Container>
      <Container style={styles.mainContainer} >
        <Row>
          <Col
            xl={{span: 9, offset: 0}}
            lg={{span: 9, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <Row style={styles.titleBox}>
              <Col>
                <div style={styles.info}>
                  <span>
                    DATE: {moment(post.dateTimestamp).format('YYYY-MM-DD')}
                  </span>
                  <span>
                    VIEW: {viewCount}
                  </span>
                </div>
                <div style={styles.subject}>{post.title}</div>
              </Col>
            </Row>
            <Row style={styles.contentBox}>
              <Col className="ql-snow">
                <div dangerouslySetInnerHTML={
                  {
                    __html: post.content
                  }
                } className="ql-editor" style={{paddingLeft:0, lineHeight: 2.0}}>
                </div>
              </Col>
            </Row>
            <Row style={styles.attachmentBox}>
              <Col style={{padding: 0}}>
                <ListAttachments
                  title="첨부파일"
                  items={post.attachments}
                  actionDelete={handleAttachmentDelete}
                  />
              </Col>
            </Row>
            <Row style={styles.buttonBox}>
              <Col style={{padding: 0}}
                sm={{span: 6, offset: 0}}
                md={{span: 6, offset: 0}}
                xs={{span: 6, offset: 0}}
              >
                  <Button style={styles.buttonLeft} href={`/${category}`}>Liste</Button>
              </Col>
              <Col style={{justifyContent: 'flex-end', display: 'flex', padding: 0}}
                sm={{span: 6, offset: 0}}
                md={{span: 6, offset: 0}}
                xs={{span: 6, offset: 0}}
              >
                {
                  userSignedIn && (
                    <>
                      <Button style={styles.buttonRight} href={`/write-post/${post.id}`}>Ändern</Button>
                      <Button style={styles.cancelButton} onClick={() => setShow(true)}>Löschen</Button>
                    </>
                  )
                }
              </Col>
            </Row>
          </Col>
          <Col style={styles.colSidebar}
            xl={{span: 3, offset: 0}}
            lg={{span: 3, offset: 0}}
            md={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            xs={{span: 12, offset: 0}}
          >
            <SidebarMenuDE />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ViewPostDE
