import React, { useLayoutEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { themeColors } from '../Styles'
import PageTitleBanner from '../components/PageTitleBanner'
import SidebarMenuDE from '../components/SidebarMenuDE'

const styles = {
  headerContainer: {
    padding: 0
  },
  mainContainer: {
    padding: '2rem 2% 1rem 2%'
  },
  row: {
    marginBottom: '25px'
  },
  colLeft: {
    paddingBottom: '7%',
    alignSelf: 'flex-start',
  },
  colSidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    marginTop: '-14px',
    paddingBottom: '7%',
  },
  colImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '7% auto',
  },
  bannerImage: (size) => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width:(size[0]>=2000)?'500px':((size[0]<768)?'300px':'30vw'),
      textAlign: 'center'
    }
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '42px',
    fontWeight: 'bold'
  },
  introTitle: {
    marginBottom: '38px',
    color: themeColors.primary,
  },
  introSubtitle: {
    marginBottom: '23px',
    color: '#2B2B2B',
    fontWeight: 'bold'
  },
  subtitleTag: {
    marginBottom: '0px',
    color: themeColors.primary,
  },
  historyDetail: {
    color: themeColors.primary,
    fontSize: '14px'
  },
  card: {
    height: '250px',
    marginBottom: '10px',
    borderWidth: 0,
    borderRadius: '7px 7px 7px 7px',
    boxShadow: '0 2px 10px 0 rgba(21, 87, 153, 0.1), 0 4px 15px 0 rgba(21, 87, 153, 0.05)'
  },
  cardSubtitle: {
    fontSize: '19px',
    color: '#2B2B2B',
    fontWeight: 'bold'
  }
  ,
  cardText: {
    fontSize: '14px',
    color: '#575757',
    fontWeight: 'normal'
  },
  sidebarTitle: {
    paddingLeft: 0,
    backgroundColor:"#FAFAFB",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 'solid 3px',
    borderRadius: '20px 0px 0px 0px',
    color: themeColors.primary,
    fontWeight:'bold',
    fontSize: '20px'
  },
  sidebarMenu: {
    backgroundColor:"#FAFAFB",
    paddingLeft: 0,
    borderWidth:0,
    color: '#767676',
    fontsize: '14px',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.125)',
  }
}


const GreetingsDE = () => {
  document.title = 'Grußwort'

  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div style={{backgroundColor:"#FAFAFB"}}>
      <Container fluid style={styles.headerContainer}>
        <PageTitleBanner
          imageUrl="/images/title-banner-bg-o.png"
          title="Grußwort"
        />
      </Container>
      <Container style={styles.mainContainer} >
        <Row style={styles.row}>
          <Col style={styles.colLeft}
            xl={{span: 5, offset:0}}
            lg={{span: 5, offset:0}}
            md={{span: 12, offset:0}}
            sm={{span: 12, offset:0}}
            xs={{span: 12, offset:0}}
          >
            <h5 style={{marginBottom:'0px'}}>Willkommen auf der Seite des</h5>
            <h1 style={styles.introTitle}>KEID (Koreanisches Erziehungsinstitut in Deutschland)</h1>
            <p>Korea und Deutschland verbindet eine lange Zusammenarbeit und eine besondere Freundschaft.</p>
            <p>Die Homepage des KEID bietet sowohl Informationen für die koreanischen Mitbürger in Deutschland,
              die Informationen im Bereich Bildung benötigen, als auch für deutsche Mitbürger,
              die sich für koreanische Sprach-, Bildungs-, Geschichts- und Kulturerlebnisse interessieren.
              So finden Sie unter anderem Unterrichtsmaterialien,
              Berichte sowie Veranstaltungshinweise von 32 koreanischen Schulen in Deutschland.
              Darüber hinaus unterstützt das KEID Koreanisch als Schulfach oder AG an allen deutschen Schulen,
              die ihren Schülern den Erwerb der koreanischen Sprache
              und die Auseinandersetzung mit der koreanischen Kultur ermöglichen möchten.</p>
            <p>Auch zukünftig wird sich das KEID aktiv für den Austausch koreanischer
              und deutscher Bildungseinrichtungen einsetzen, um Begegnungen zwischen den Kulturen
              und zahlreiche Beziehungen zwischen den Menschen beider Länder zu ermöglichen. </p>
            <p>Wir bedanken uns für Interesse und freuen uns über Ihre Anregungen.
              Vielen Dank!</p>
            <h5 style={{fontWeight: 'bold'}}>Jisun Lee, Direktorin</h5>
          </Col>
          <Col style={styles.colImage}
            xl={{span: 4, offset:0}}
            lg={{span: 4, offset:0}}
            md={{span: 12, offset:0}}
            sm={{span: 12, offset:0}}
            xs={{span: 12, offset:0}}
          >
            <Image src="/images/img-about.png" style={styles.bannerImage(size)} />
          </Col>
          <Col style={styles.colSidebar}
            xl={{span: 3, offset:0}}
            lg={{span: 3, offset:0}}
            md={{span: 12, offset:0}}
            sm={{span: 12, offset:0}}
            xs={{span: 12, offset:0}}
          >
            <SidebarMenuDE />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default GreetingsDE
